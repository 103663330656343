import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {

  constructor(private http: HttpClient) { }

  getBusinessUnits() {
    return this.http.get(env.configUrl + "BusinessUnit");
  }
}
