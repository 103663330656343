import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms'
import { Interceptor } from './service/interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { DashboardCopyComponent } from './dashboard-copy/dashboard-copy.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { LoaderComponent } from './loader/loader/loader.component';
import { ModelComponent } from './popup/model/model.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DayViewComponent } from './day-view/day-view.component';
import { SessionComponent } from './session/session.component';
import { MobileViewComponent } from './mobile-view/mobile-view.component';
import { AddshiftBorrowedComponent } from './addshift-borrowed/addshift-borrowed.component';
import { AddEditShiftComponent } from './add-edit-shift/add-edit-shift.component';
import { ResizableModule } from 'angular-resizable-element';
import { FormulaSetupComponent } from './formula-setup/formula-setup.component';

@NgModule({
  declarations: [
    AppComponent,
    MyProfileComponent,
    DashboardComponent,
    HeaderComponent,
    DashboardCopyComponent,
    LoaderComponent,
    ModelComponent,
    DayViewComponent,
    SessionComponent,
    MobileViewComponent,
    AddshiftBorrowedComponent,
    AddEditShiftComponent,
    FormulaSetupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    DpDatePickerModule,
    ToastrModule.forRoot({
      timeOut: 0,
      positionClass: 'toast-top-full-width',

    }),
    BrowserAnimationsModule,
    ResizableModule
    //NgSelect2Module
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
