import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EmployeeScheduleService } from '../service/employee-schedule.service'
import { ScheduleNotesService } from '../service/schedulenotes.service'
import { ParamEmployeeSchedule, ParamEmployeeScheduleSave } from '../Models/ParamEmployeeSchedule';
import { DateService } from '../service/date.service';
import { Subscription, Subject, Observable, zip } from 'rxjs';
import { ModalService } from '../service/modal.service';
import { EmployeeScheduleModel, ExportEmployeeSchedule, ExportEmployeeScheduleTotalHours, TimeDetails } from '../Models/EmployeeScheduleModel';
import * as moment from 'moment';
import { DatePipe, DecimalPipe, Time } from '@angular/common';
import { CurrentuserService } from '../service/currentuser.service'
import { MessageService } from '../service/message.service'
import { ToastrService } from 'ngx-toastr';
import { ScheduleNotes } from '../Models/schedulenotes.model';
import { CopyFromDates } from '../Models/copy-from-dates';
import { ParamsCopySchedule } from '../Models/params-copy-schedule';
import { EmployeeService } from '../service/employee/employee.service'
import { BusinessUnitService } from '../service/business-unit/business-unit.service'
import { BusinessUnitModel } from '../Models/BusinessUnitModel';
import { Router } from "@angular/router";
import { AddshiftBorrowedComponent } from '../addshift-borrowed/addshift-borrowed.component';
import { AddEditShiftComponent } from '../add-edit-shift/add-edit-shift.component';
import { LoaderService } from '../service/loader.service';
import { FormulaSetupModel } from '../Models/FormulaSetupModel';
import { FormulaSetupService } from '../service/formula-setup/formula-setup.service';
import '../extention/date-extention';

import * as _ from 'lodash';
import { MapOperator } from 'rxjs/internal/operators/map';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { delay, timeout } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [EmployeeScheduleService, ScheduleNotesService, DateService, DatePipe, CurrentuserService, DecimalPipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(AddshiftBorrowedComponent) addshiftBorrowedComponent: AddshiftBorrowedComponent;
  @ViewChild(AddEditShiftComponent) adEditShiftComponent: AddEditShiftComponent;
  strDateFormat: string = "MM/DD/YYYY";
  strDateFormatDB: string = "yyyy-MM-dd";
  strTimeFormatDB: string = "HH:mm:ss";

  bodyText: string;
  paramEmployeeSchedule = new ParamEmployeeSchedule();
  paramEmployeeScheduleSave = new ParamEmployeeScheduleSave();
  currentUserSession: any;
  listScheduleSubject: Subject<any> = new Subject();
  listSchedule: any;
  listScheduleCopy: any;

  selectedDate: Date;
  payPeriod: any;
  _subscription = new Subscription();
  subscription: Subscription;
  employeeScheduleModel = new EmployeeScheduleModel();
  employeeScheduleModelCopy = new EmployeeScheduleModel();
  tShow: boolean = true;
  tShowNotes: boolean = false;
  strSelectedTime: string;
  iBusinessUnitId: number;// = 1;
  scheduleNotes = new ScheduleNotes();
  listDates: Array<CopyFromDates>;
  // Property to set configuration
  datePickerConfig = {
    format: "MM/DD/YYYY",
    firstDayOfWeek: "su",
    unSelectOnClick: false,
    disableKeypress: true
  };
  datepickerTheme = "angular-datepicker";

  tIsSubmitted: boolean = false;
  dblHRLimit: number = 0;

  iTotalEmployees: number;

  listDayTime: Array<any> = [];
  listDayTimeCount: Array<Number> = [];
  workDayStart: any = "2019-01-01 08:00:00";
  lastYearTotal: any = {
    dblTotalHours: 0.0,
    dblTotalPay: 0.0,
    dblPercentage: 0.0
  };

  sales: any = {
    lastweekactual: 0,
    lastyearactual: 0,
    lastYearGuest: 0
  };
  strCurrentComparision: string = "Last week";
  strCurrentComparisionLastYear: string = "Last Year";
  listBusinessUnit: Array<BusinessUnitModel>;
  listBusinessUnitEM: Array<any>;
  //tShowBusinessUnit: boolean = true;
  listUserDepartmentIds: Array<number>;
  listScheduleByJob: any = [];
  tFilterDeaprtment: boolean = false;
  shiftTotalHours: number = 0;
  search: string = '';
  tShowEmployeeFilter: boolean = false;
  paramsCopySchedule: ParamsCopySchedule;
  listTerminatedEmployees: any = [];
  popupErrorBox: any = { tShowErrorBox: false, strErrorMessage: '' };
  shiftStart: any = "2019-01-01 08:00:00";
  strWebsiteURL: string;
  strBuName: string;
  trsanferSchedule: EmployeeScheduleModel;
  listSlots = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  strCustomerGroup: string;
  selectedBusinessUnit: any;
  iComparisionFilterLastYear: number = 1;
  showLoader: boolean = false;
  showExcelLoader: boolean = false;
  dayViewWeather: any = { tempMax: 0, tempMin: 0, icon: '', tWeatherAvailable: false };
  totalForcast = { forcastSales: 0, forcastGuest: 0, tForcastAvailable: false };
  strIndustrySubType: string;

  tIsPublished = false;
  tCanPublishSchedule = false;
  tCanEditPublishedSchedule = false;
  iWeekStatus = 1;
  tHasSchedule = false;

  formulaSetupModel = new FormulaSetupModel();
  listWeatherData = {};
  listWeatherDayView = {};
  todaydate = new Date();
  listTimeDetails:any=[];
  
  fileName:string = '';
  listExportToExcelSchedule = [];
  listExportScheduleTotalHours = [];
  totoalHourSum :any = 0;
  Finaldata:any = [];
  constructor(
    private _empScheduleService: EmployeeScheduleService,
    private _DateService: DateService,
    private _modalService: ModalService, private _datePipe: DatePipe,
    private _currentUserService: CurrentuserService,
    private messageService: MessageService, private toastr: ToastrService,
    private _scheduleNotesService: ScheduleNotesService, private _employeeService: EmployeeService,
    private _businessUnitService: BusinessUnitService,
    private router: Router,
    private loaderService: LoaderService,
    private _setupService: FormulaSetupService) {
    this.listSchedule = {
      listScheduleDays: [], listScheduleByJob: [], listDayTime: [],
      dblScheduleHoursFinalPay: 0, dblScheduleHoursFinalTotal: 0, iTotalSchedules: 0, listLaborDayPart: []
    };
    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];

    this._subscription.add(this.messageService.getMessage().subscribe(message => {
      this.iBusinessUnitId = message.iSelectedBusinessUnitId;
      this.listBusinessUnitEM = message.listBu;

      this.selectedBusinessUnit = this.listBusinessUnitEM.find(x => { return x.iEMLocationId == message.iSelectedBusinessUnitId });

      this.getBusinessUnits();
      this.onLocationChange();      
    }));

    this.paramEmployeeSchedule.tScheduleByDay = false;
    this.lastYearTotal = {
      dblTotalHours: 0,
      dblTotalPay: 0,
      dblPercentage: 0
    };
    this.payPeriod = {
      payrollPeriodStartDate: new Date(),
      payrollPeriodEndDate: new Date()
    };

  }

  arrayOne(n: number, remove: number) {
    return Array(n - remove);
  }
  //Below Method is used to set Hover event on Calender
  setCalenderHover() {
    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      $(".dp-calendar-day").hover(function () {
        $(".dp-calendar-week").removeClass("weekhighlight");
        $(this).parent().addClass("weekhighlight");
      });
    }
    else
      $(".dp-calendar-week").removeClass("weekhighlight");
  }
  //Below Method is used to update payroll week end days based on configuration

  updatePayrollWeekEndDay() {

    this.payPeriod = this._DateService.getPayrollDates(this.selectedDate);

    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      this.selectedDate = this.payPeriod.payrollPeriodEndDate;
    }
    else {
      this.payPeriod = {
        payrollPeriodEndDate: this.selectedDate,
        payrollPeriodStartDate: this.selectedDate
      };
    }
    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
    this.getEmployeScheduleDetails(true);
  }

  setdefaultDate() {
    this.payPeriod = this._DateService.getPayrollDates(new Date());
    this.selectedDate = this.payPeriod.payrollPeriodEndDate;
    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
  }

  getPayrollStatus() {
    this._currentUserService.getPayrollStatus(this.payPeriod.payrollPeriodStartDate,
      this.payPeriod.payrollPeriodEndDate).subscribe((res: any) => {
        if (res != undefined) {
          this.tIsSubmitted = res.data == undefined ? false : res.data.tIsSubmitted;
        }
      });
  }

  getBusinessUnitConfig() {

    this._currentUserService.getBusinessUnitConfig(this.iBusinessUnitId).subscribe((res: any) => {
      if (res != undefined) {
        this.dblHRLimit = res.data.dblDailyRegHours == 0 ? 8 : res.data.dblDailyRegHours;
        if (res.data.dateTimeShiftStart != null && res.data.dateTimeShiftStart != undefined)
          this.shiftStart = "2019-01-01 " + res.data.dateTimeShiftStart;
      }
    });
  }

  getPreviousWeekDates() {
    this.listDates = [];
    var iDay = 7;
    for (var i = 0; i < 5; i++) {
      var copyFromDates = new CopyFromDates();
      copyFromDates.periodEndDate = moment(this.payPeriod.payrollPeriodEndDate, 'MM/DD/YYYY')
        .subtract((iDay + (i * iDay)), 'days').format('MM/DD/YYYY');
      copyFromDates.periodStartDate = moment(this.payPeriod.payrollPeriodStartDate, 'MM/DD/YYYY')
        .subtract((iDay + (i * iDay)), 'days').format('MM/DD/YYYY');
      copyFromDates.strDate = moment(copyFromDates.periodStartDate).format("MMM D") + " - " + moment(copyFromDates.periodEndDate).format("MMM D")
      this.listDates.push(copyFromDates);
    }
  }

  selectPreviousPayrollPeriod = function () {

    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      var selectedDate = this.payPeriod.payrollPeriodEndDate;
      selectedDate = moment(selectedDate, 'MM/DD/YYYY').subtract(this.payPeriod.payrollPeriod, 'days').format('MM/DD/YYYY');
      this.payPeriod = this._DateService.getPayrollDates(selectedDate);
      this.selectedDate = this.payPeriod.payrollPeriodEndDate;
    }
    else {

      this.payPeriod = {
        payrollPeriodEndDate: moment(this.selectedDate, 'MM/DD/YYYY').add(-1, 'days').format('MM/DD/YYYY'),
        payrollPeriodStartDate: moment(this.selectedDate, 'MM/DD/YYYY').add(-1, 'days').format('MM/DD/YYYY')
      };
      this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    }
    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
    this.getEmployeScheduleDetails();
  };

  selectNextPayrollPeriod = function () {
    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      var selectedDate = this.payPeriod.payrollPeriodEndDate;
      selectedDate = moment(selectedDate, 'MM/DD/YYYY').add(this.payPeriod.payrollPeriod, 'days').format('MM/DD/YYYY');
      this.payPeriod = this._DateService.getPayrollDates(selectedDate);
      this.selectedDate = this.payPeriod.payrollPeriodEndDate;
    }
    else {
      this.payPeriod = {
        payrollPeriodEndDate: moment(this.selectedDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY'),
        payrollPeriodStartDate: moment(this.selectedDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY')
      };
      this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    }
    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
    this.getEmployeScheduleDetails();
  };

  Login() {
    this._currentUserService.getUserSession().subscribe((res: any) => {
      if (res.token == null) {
        this.router.navigate(['/session']);
        return;
      }
      if (res != undefined && res.token != undefined) {
        localStorage.setItem("access-token", "Bearer " + res.token.access_token);
        localStorage.setItem("userEmailAddress", res.strEmailAddress);
        this.iBusinessUnitId = res.listBusinessUnit[0].iEMLocationId;
        this.messageService.sendSelectedBusinessUniIdOnDashboard(this.iBusinessUnitId);
        this.listBusinessUnitEM = res.listBusinessUnit;
        this.strIndustrySubType = res.strIndustrySubType;
        this.selectedBusinessUnit = this.listBusinessUnitEM.find(x => { return x.iEMLocationId == this.iBusinessUnitId });
        this.strCustomerGroup = res.strCustomerGroup;
        this.strWebsiteURL = res.strWebsiteURL;
        this.paramEmployeeSchedule.listUserDepartmentAccess = res.listUserDepartmentAccess;
        this.listUserDepartmentIds = res.listUserDepartmentAccess;
        this.tCanPublishSchedule = res.tCanPublishSchedule;
        this.tCanEditPublishedSchedule = res.tCanEditPublishedSchedule;

        this._currentUserService.getPayrollUnitConfiguration().subscribe((payrollunitRep: any) => {
          if (payrollunitRep != undefined) {
            this._DateService.setEMStartDate(payrollunitRep.data.dateTimeEMStart);
            this.datePickerConfig.firstDayOfWeek = moment(payrollunitRep.data.dateTimeEMStart).format('dd').toLowerCase();
            this.getFormula();
            this.setdefaultDate();
            this.getEmployeScheduleDetails(true);
            this.getBusinessUnits();
          }
        });

      }
    });
  }

  ngOnInit() {
    this.bodyText = '';
    this.Login();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getEmployeScheduleDetails(loadSalesForecastAndWeather: boolean = false) {
    if (this.payPeriod != undefined) {
      this.paramEmployeeSchedule.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
      this.paramEmployeeSchedule.iBusinessUnitId = this.iBusinessUnitId;
      this.paramEmployeeSchedule.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;

      if (this.paramEmployeeSchedule.tScheduleByDay) {
        this.paramEmployeeSchedule.dateTimeTo = this.payPeriod.payrollPeriodStartDate;
      }

      this.paramEmployeeSchedule.tIsActual = this.paramEmployeeSchedule.tIsActual && !this.paramEmployeeSchedule.tScheduleByDay;
      this._subscription.add(this._empScheduleService.getEmployeeScheduleDetails(this.paramEmployeeSchedule)
        .subscribe((res: any) => {
          this.tHasSchedule = false;

          this.listSchedule = res.data;
          if (this.listSchedule != null || this.listSchedule != undefined) {
            this.tIsPublished = res.data.tIsPublished;
            this.iWeekStatus = res.data.iWeekStatus;

            if (!this.tFilterDeaprtment) {
              var listDepartmentGroup: any = [];
              this.listSchedule.listScheduleByJob.forEach((d) => {
                var departmentGroup = {
                  iDepartmentId: d.iDepartmentId,
                  strDepartmentName: d.strJobTitleName.split('-')[0].trim(),
                  iTotalEmployees: d.listEmployee.length
                };
                if (listDepartmentGroup.filter(e => e.iDepartmentId === d.iDepartmentId).length > 0) {
                  listDepartmentGroup.find(e => e.iDepartmentId === d.iDepartmentId).iTotalEmployees += d.listEmployee.length;
                }
                else {
                  listDepartmentGroup.push(departmentGroup);
                }
                d.listEmployee.forEach(emply => {
                  emply.expand = false;
                  var buHomeDetail = this.listBusinessUnitEM.filter(tt => tt.iEMLocationId === emply.iHomeBusinessUnitId);
                  if (buHomeDetail.length > 0)
                    emply.strHomeBusinessUnitName = buHomeDetail[0].strBusinessUnitDisplayName;

                  emply.listEmployeeScheduleByDate.forEach(days => {
                    days.DayMonth = this._datePipe.transform(days.dateTimeBusinessDate, "yyyyMMdd");
                    days.expand = false;

                    days.listEmployeeSchedule.forEach(schl => {
                      if (!this.tHasSchedule && schl.iEmployeeScheduleId > 0) {
                        //check whether schedule has actual data then date must be same or after
                        //if actual schedule not loaded then mark has schedule as true
                        let tIsCurrentOrAfterDate = moment(schl.dateTimeBusinessDate).isSameOrAfter(moment().startOf('day'));
                        if (!this.paramEmployeeSchedule.tIsActual || (this.paramEmployeeSchedule.tIsActual && tIsCurrentOrAfterDate)) {
                          this.tHasSchedule = true;
                        }
                      }

                      if (days.tIsActual)
                        schl.strColorCode = schl.strColorCode + "_5";
                      var buDetail = this.listBusinessUnitEM.filter(tt => tt.iEMLocationId === schl.iBusinessUnitId);
                      if (buDetail.length > 0)
                        schl.strBusinessUnitName = buDetail[0].strBusinessUnitDisplayName;
                    });
                  });
                });
              });
              this.listScheduleByJob = listDepartmentGroup;
              var listEmployees = _.map(this.listSchedule.listScheduleByJob, "listEmployee");
              this.iTotalEmployees = 0;
              let listAllEmployee = [];
              listEmployees.forEach((emp) => {
                emp.map(e => e.iEmployeeId).forEach(iEmployeeId => listAllEmployee.push(iEmployeeId));
              });
              this.iTotalEmployees = listAllEmployee.map(iEmployeeId => iEmployeeId).filter((iEmployeeId, index, self) => self.indexOf(iEmployeeId) === index).length;
            }
            this.setExportExcelData();
          }
          this.listDayTime = this.listSchedule.listDayTime;

          this.getLaborForcast(this.paramEmployeeSchedule);
          this.getForcast(this.paramEmployeeSchedule);

          if (this.formulaSetupModel.formulaModel.tShowSalesOnScheduling == true)
            this.getLastYearDetails();


          //Get weather data
          this.setWeatherData(this.listWeatherDayView);
          this.setDayViewWeather(this.listWeatherData);
          if (!this.paramEmployeeSchedule.tScheduleByDay)
            this.getWeatherData(this.paramEmployeeSchedule);
          else {
            this.getWeatherDayViewData();
            this.getWeatherData(this.paramEmployeeSchedule);
          }

        })
      );

    }
  }

  getLastYearDetails() {
    var businessUnit = Object.assign([], this.listBusinessUnitEM).filter(
      item => item.iEMLocationId == this.paramEmployeeSchedule.iBusinessUnitId
    );
    this.paramEmployeeSchedule.iBusinessUnitId = businessUnit[0].iBuId;

    this.getLastWeekActual(this.paramEmployeeSchedule);
    this.getLastYearActual(this.paramEmployeeSchedule);
  }

  getLaborForcast(employeeSchedule) {
    let { paramObject, paramEmployeeSchedule } = this.getParamInfo(employeeSchedule);
    var businessUnit = Object.assign([], this.listBusinessUnitEM).filter(
      item => item.iEMLocationId == this.paramEmployeeSchedule.iBusinessUnitId
    );


    if (this.formulaSetupModel.formulaModel.tShowForecastOnScheduling) {
      var dates = this._DateService.getPayrollDates(paramEmployeeSchedule.dateTimeTo);
      paramObject.dateTimeStart = dates.payrollPeriodStartDate;
      paramObject.dateTimeEnd = dates.payrollPeriodEndDate;
      paramObject.iBusinessUnitId = businessUnit[0].iBuId;
      paramObject.tFromIndustry = this.formulaSetupModel.formulaModel.salesSetup.iForcastFrom == 2 ? true : false;

      if (this.formulaSetupModel.formulaModel.tShowForecastOnScheduling == true) {
        this._subscription.add(this._empScheduleService.getLaborForcast(paramObject)
          .subscribe((response: any) => {
            this.setLaborForecastData(response);

          })
        );
      }

    }
  }

  getForcast(employeeSchedule) {
    let { paramObject, paramEmployeeSchedule } = this.getParamInfo(employeeSchedule);
    var businessUnit = Object.assign([], this.listBusinessUnitEM).filter(
      item => item.iEMLocationId == this.paramEmployeeSchedule.iBusinessUnitId
    );
    paramObject.iBusinessUnitId = businessUnit[0].iBuId;
    var paramGetSchedule = Object.assign({}, employeeSchedule);
    paramGetSchedule.iBusinessUnitId = businessUnit[0].iBuId;
    let actualSalse = this._empScheduleService.getActualSales(paramGetSchedule);
    let forcastSales = this._empScheduleService.getForcast(paramObject);
    let discount = this._empScheduleService.getDiscountSales(paramGetSchedule);
    this._subscription.add(zip(actualSalse, forcastSales, discount)
      .subscribe((res: any) => {
        let listActualSales = []; let listDiscountSales = [];
        if (res[2] != null && res[2] != undefined && res[2].data != undefined && res[2].data != null)
          listDiscountSales = res[2].data;

        if (this.formulaSetupModel.formulaModel.salesSetup.iForcastFrom == 1) {
          if (res[0] != null && res[0] != undefined && res[0].tIsSuccess != undefined && res[0].tIsSuccess != null && res[0].tIsSuccess)
            listActualSales = res[0].data.listSalesSummary;

          this.setForcastData(res[1].data, listActualSales, listDiscountSales);
        }
        else {
          if (res[0] != null && res[0] != undefined && res[0].tIsSuccess != undefined && res[0].tIsSuccess != null && res[0].tIsSuccess)
            listActualSales = res[0].data.listSalesSummary;

          this.setForcastData(res[1], listActualSales, listDiscountSales);
        }
      })
    );
  }

  private getParamInfo(employeeSchedule: any) {
    let paramEmployeeSchedule = Object.assign([], employeeSchedule);
    let industry = false;

    if (this.formulaSetupModel.formulaModel.salesSetup.iForcastFrom == 2)
      industry = true;
    let paramObject = {
      dateTimeStart: paramEmployeeSchedule.dateTimeFrom,
      dateTimeEnd: paramEmployeeSchedule.dateTimeTo,
      iBusinessUnitId: paramEmployeeSchedule.iBusinessUnitId,
      tFromIndustry: industry
    };
    if (this.paramEmployeeSchedule.tScheduleByDay) {
      paramObject = {
        dateTimeStart: this._datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
        dateTimeEnd: this._datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
        iBusinessUnitId: paramEmployeeSchedule.iBusinessUnitId,
        tFromIndustry: industry
      };
    }
    return { paramObject, paramEmployeeSchedule };
  }

  setLaborForecastData(res) {

    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      this.listSchedule.listScheduleDays.forEach(day => {

        if (res != undefined && res != null) {
          var objLaborForecast = res.filter(tt => new Date(day.dateTimeBusinessDate).getDay() === tt.dayOfWeek);
          if (objLaborForecast != null)
            day.iTotalHours = objLaborForecast[0].iTotalHours;
          else
            day.iTotalHours = 0;
        }
        else
          day.iTotalHours = 0;
      });
    }
    else {
      var selectedDate = this.selectedDate;
      var date = new Date(selectedDate);
      this.listSchedule.listLaborDayPart.forEach(day => {
        if (res != undefined && res != null) {
          var objLaborForecast = res.filter(tt => new Date(day.dateTimeEnd).getDay() === tt.dayOfWeek);
          if (objLaborForecast != null) {
            var listForecast = objLaborForecast[0].listLaborForecastByTime;

            day.iTotalHours = 0;


            //  var forecastStartDate = new Date(day.dateTimeStart).setMinutes(new Date(day.dateTimeStart).getMinutes() + 1);
            var laborForecasttime = this._datePipe.transform(day.dateTimeStart, "yyyy-MM-dd HH:mm");
            var forecastEndDate = new Date(day.dateTimeEnd).setHours(new Date(day.dateTimeEnd).getHours() + 1);
            var laborForecasttimeEnd = this._datePipe.transform(forecastEndDate, "yyyy-MM-dd HH:mm");

            listForecast.forEach(time => {
              var datetimeString = new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + time.timeSpanStart);
              var dateString = new Date(datetimeString).setMinutes(new Date(datetimeString).getMinutes() + 1);
              var forecastedTime = this._datePipe.transform(dateString, "yyyy-MM-dd HH:mm");

              if (forecastedTime >= laborForecasttime && forecastedTime <= laborForecasttimeEnd)
                day.iTotalHours = day.iTotalHours + time.iProjectedTransaction;
              else
                day.iTotalHours += 0;


            });
          }
          else
            day.iTotalHours = 0;
        }
        else
          day.iTotalHours = 0;
      });
    }
  }

  setForcastData(res, resActual, listDiscountSales) {
    this.totalForcast = { forcastSales: 0, forcastGuest: 0, tForcastAvailable: false };
    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      this.listSchedule.listScheduleDays.forEach(day => {

        day.forcastSales = 0;
        day.forcastGuest = 0;
        day.tForcastAvailable = false;
        let check = new Date(day.dateTimeBusinessDate).getUTCDateExtention() < new Date().getUTCDateExtention();
        if (this.paramEmployeeSchedule.tIsActual && check) {
          if (resActual.length > 0) {
            var actual = resActual.find(tt => this._datePipe.transform(day.dateTimeBusinessDate, this.strDateFormatDB) === this._datePipe.transform(tt.dateBusinessDay, this.strDateFormatDB));
            if (actual != undefined && actual != null && this.formulaSetupModel.formulaModel.tShowSalesOnScheduling) {
              let discount = 0;
              //if (this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1) {
              var disc = listDiscountSales.find(tt => this._datePipe.transform(day.dateTimeBusinessDate, this.strDateFormatDB) === this._datePipe.transform(tt.dateBusinessDay, this.strDateFormatDB));
              discount = disc.dblTotalDiscount;
              //}

              day.forcastSales = this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1 ? (actual.dblNetTotal - discount) : actual.dblGrossSalesTotal;
              day.tForcastAvailable = true;

              if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 1)
                day.forcastGuest = actual.iTransactionCount;
              else if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 2) {
                let total = ((actual.iTransactionCount) - (actual.iRefundCount));
                day.forcastGuest = total;
              }
              else if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 3)
                day.forcastGuest += actual.iGuestCount;

              this.totalForcast.forcastSales += this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1 ? (actual.dblNetTotal - discount) : actual.dblGrossSalesTotal;
              this.totalForcast.forcastGuest += day.forcastGuest;
              this.totalForcast.tForcastAvailable = true;
            }
          }
        }
        else {
          if (res != undefined && res != null
            && this.formulaSetupModel.formulaModel.tShowForecastOnScheduling) {
            var forcast = res.filter(tt => this._datePipe.transform(day.dateTimeBusinessDate, "yyyyMMdd") === this._datePipe.transform(tt.dateTimeBusinessDate, "yyyyMMdd"));
            if (forcast != null && forcast != undefined && forcast.length > 0) {
              let guestCount = this.formulaSetupModel.formulaModel.salesSetup.iForcastFrom == 1 ? forcast[0].iGuest : 0;

              day.forcastSales = forcast[0].dblSales;
              day.forcastGuest = guestCount;
              day.tForcastAvailable = true;

              this.totalForcast.forcastSales += forcast[0].dblSales;
              this.totalForcast.forcastGuest += guestCount;
              this.totalForcast.tForcastAvailable = true;
            }
          }
        }
      });
    }
    else {
      if (res != undefined && res != null && this.formulaSetupModel.formulaModel.tShowForecastOnScheduling) {
        var forcast = res.filter(tt => this._datePipe.transform(this.selectedDate, "yyyyMMdd") === this._datePipe.transform(tt.dateTimeBusinessDate, "yyyyMMdd"));
        if (forcast != null && forcast != undefined && forcast.length > 0) {
          this.totalForcast = { forcastSales: forcast[0].dblSales, forcastGuest: this.formulaSetupModel.formulaModel.salesSetup.iForcastFrom == 1 ? 0 : forcast[0].iGuest, tForcastAvailable: true };
        }
      }
    }
  }

  getLastWeekActual(employeeSchedule) {

    let paramEmployeeSchedule = Object.assign([], employeeSchedule);
    var lastweekDates = this._DateService.getPayrollDates(moment(paramEmployeeSchedule.dateTimeFrom).subtract(7, 'days'));
    paramEmployeeSchedule.dateTimeFrom = lastweekDates.payrollPeriodStartDate;
    paramEmployeeSchedule.dateTimeTo = lastweekDates.payrollPeriodEndDate;
    let Actuals = this._empScheduleService.getActualSales(paramEmployeeSchedule);

    this._subscription.add(zip(Actuals)
      .subscribe((response: any) => {
        let res = response[0];
        this.sales.lastweekactual = 0;
        for (let index = 0; index < res.data.listSalesSummary.length; index++) {
          if (this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1)
            this.sales.lastweekactual += res.data.listSalesSummary[index].dblNetTotal;
          else
            this.sales.lastweekactual += res.data.listSalesSummary[index].dblGrossSalesTotal;
        }

        if (this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1) {
          let dblDiscount = 0;
          this._empScheduleService.getDiscountSales(paramEmployeeSchedule)
            .subscribe((disc: any) => {
              if (disc.tIsSuccess && disc.data != null && disc.data != undefined) {
                disc.data.forEach(dis => {
                  dblDiscount += dis.dblTotalDiscount;
                });
                this.sales.lastweekactual = this.sales.lastweekactual - dblDiscount;
              }
            })
        }
      })
    );
  }

  getBudget(employeeSchedule) {
    let paramEmployeeSchedule = Object.assign([], employeeSchedule);
    let paramObject = {};
    if (this.formulaSetupModel.formulaModel.salesSetup.iBudgetFrom == 1) {
      paramObject = {
        dateTimeStart: paramEmployeeSchedule.dateTimeFrom,
        dateTimeEnd: paramEmployeeSchedule.dateTimeTo,
        listBusinessUnitIds: [paramEmployeeSchedule.iBusinessUnitId],
        tFromIndustry: false
      };
    }
    else {
      paramObject = {
        dateTimeStart: paramEmployeeSchedule.dateTimeFrom,
        dateTimeEnd: paramEmployeeSchedule.dateTimeTo,
        iBusinessUnitId: paramEmployeeSchedule.iBusinessUnitId,
        strCompanyNameId: this.selectedBusinessUnit.strCompanyNameId,
        tFromIndustry: true
      };
    }

    this._subscription.add(this._empScheduleService.getBudget(paramObject)
      .subscribe((res: any) => {

        if (res.tIsSuccess) {
          if (res.data.length > 0) {
            this.sales.lastyearactual = this.sales.lastyearactual + ((this.sales.lastyearactual * res.data[0].dblBudgetPerc) / 100);
            this.sales.lastYearGuest = this.sales.lastYearGuest + ((this.sales.lastYearGuest * res.data[0].dblGuestCountBudgetPerc) / 100);
          }
          else {
            this.sales.lastyearactual = 0;
            this.sales.lastYearGuest = 0;
          }
        }
      })
    );
  }

  getLastYearActual(employeeSchedule) {
    let paramEmployeeSchedule = Object.assign([], employeeSchedule);
    if (this.paramEmployeeSchedule.tScheduleByDay) {
      let lyDate = moment(this.selectedDate).subtract(364, 'days');
      paramEmployeeSchedule.dateTimeFrom = lyDate.format('MM/DD/YYYY');
      paramEmployeeSchedule.dateTimeTo = lyDate.format('MM/DD/YYYY');
    }
    else {
      let lastweekDates = this._DateService.getPayrollDates(moment(paramEmployeeSchedule.dateTimeFrom).subtract(364, 'days'));
      lastweekDates.payrollPeriodEndDate = moment(lastweekDates.payrollPeriodStartDate).add(6, 'days').format('MM/DD/YYYY').toString();
      paramEmployeeSchedule.dateTimeFrom = lastweekDates.payrollPeriodStartDate;
      paramEmployeeSchedule.dateTimeTo = lastweekDates.payrollPeriodEndDate;
    }
    this._subscription.add(this._empScheduleService.getActualSales(paramEmployeeSchedule)
      .subscribe((res: any) => {

        this.sales.lastyearactual = 0;
        this.sales.lastYearGuest = 0;

        for (let index = 0; index < res.data.listSalesSummary.length; index++) {
          //-----------For Last Year Actual sales Based On Setup--------------//
          if (this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1)
            this.sales.lastyearactual += res.data.listSalesSummary[index].dblNetTotal;
          else
            this.sales.lastyearactual += res.data.listSalesSummary[index].dblGrossSalesTotal;

          //-----------For Last Year Guest Count Based On Setup--------------//
          if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 1)
            this.sales.lastYearGuest += res.data.listSalesSummary[index].iTransactionCount;
          else if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 2) {
            let total = ((res.data.listSalesSummary[index].iTransactionCount) - (res.data.listSalesSummary[index].iRefundCount));
            this.sales.lastYearGuest += total;
          }
          else if (this.formulaSetupModel.formulaModel.salesSetup.iGuestTotal == 3)
            this.sales.lastYearGuest += res.data.listSalesSummary[index].iGuestCount;
        }

        if (this.formulaSetupModel.formulaModel.salesSetup.iSalesTotal == 1) {
          let dblDiscount = 0;
          this._empScheduleService.getDiscountSales(paramEmployeeSchedule)
            .subscribe((disc: any) => {
              if (disc.tIsSuccess && disc.data != null && disc.data != undefined) {
                disc.data.forEach(dis => {
                  dblDiscount += dis.dblTotalDiscount;
                });
                this.sales.lastyearactual = this.sales.lastyearactual - dblDiscount;
              }
            })
        }
        //
        // this.payPeriod = this._DateService.getPayrollDates(this.selectedDate);
        // this.paramEmployeeSchedule.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
        // this.paramEmployeeSchedule.dateTimeTo = this.payPeriod.payrollPeriodEndDate;

        if (this.iComparisionFilterLastYear == 2) {

          this.getBudget(this.paramEmployeeSchedule);
          this.getLaborBudget(this.paramEmployeeSchedule);
        }
        else {
          this.getLastYearLabor(this.paramEmployeeSchedule);
        }
      })
    );
  }

  getLaborBudget(param) {

    let paramLaborBudget = Object.assign([], param);
    let paramObject = {
      dateTimeStart: paramLaborBudget.dateTimeFrom,
      dateTimeEnd: paramLaborBudget.dateTimeTo,
      listBusinessUnitIds: null,
      tFromIndustry: null
    };

    if (this.formulaSetupModel.formulaModel.salesSetup.iBudgetFrom == 1) {

      paramObject.listBusinessUnitIds = [this.iBusinessUnitId];
      paramObject.tFromIndustry = false;
    }
    else {
      var businessUnit = Object.assign([], this.listBusinessUnitEM).filter(
        item => item.iEMLocationId == this.iBusinessUnitId
      );
      paramObject.listBusinessUnitIds = [businessUnit[0].iBuId];

      paramObject.tFromIndustry = true;
    }

    this._subscription.add(this._empScheduleService.GetLaborBudget(paramObject)
      .subscribe((res: any) => {
        //Data Entry Form
        if (this.formulaSetupModel.formulaModel.salesSetup.iBudgetFrom == 1) {
          if (res.data != null && res.data.length > 0) {
            this.lastYearTotal.dblTotalHours = 0.0;
            this.lastYearTotal.dblTotalPay = (this.sales.lastyearactual * res.data[0].dblBudgetPerc) / 100;
            this.lastYearTotal.dblPercentage = res.data[0].dblBudgetPerc;
          }
          else
            this.lastYearTotal = { dblTotalHours: 0.0, dblTotalPay: 0.0, dblPercentage: 0.0 };
        }
        else {
          if (res != null && res.length > 0) {
            this.lastYearTotal.dblTotalHours = 0.0;
            if (res[0].StrEnumUnit === "EnumUnit.enumDollar") {
              this.lastYearTotal.dblTotalPay = res[0].DblGoalQty;
              this.lastYearTotal.dblPercentage = this.sales.lastyearactual == 0 ? 0 : ((res[0].DblGoalQty) * 100 / this.sales.lastyearactual);
            }
            else if (res[0].StrEnumUnit === "EnumUnit.enumPercantage") {
              this.lastYearTotal.dblTotalPay = this.sales.lastyearactual == 0 ? 0 : ((this.sales.lastyearactual * res[0].DblGoalQty) / 100);
              this.lastYearTotal.dblPercentage = res[0].DblGoalQty;
            }
          }
          else
            this.lastYearTotal = { dblTotalHours: 0.0, dblTotalPay: 0.0, dblPercentage: 0.0 };
        }
      })
    );
  }

  getLastYearLabor(param) {

    let paramLaborLastYear = new ParamEmployeeSchedule();
    paramLaborLastYear = Object.assign(paramLaborLastYear, param);
    paramLaborLastYear.iBusinessUnitId = this.iBusinessUnitId;
    if (this.paramEmployeeSchedule.tScheduleByDay) {
      paramLaborLastYear.dateTimeFrom = moment(this.selectedDate).format(this.strDateFormat);
      paramLaborLastYear.dateTimeTo = moment(this.selectedDate).format(this.strDateFormat);
    }

    this._subscription.add(this._empScheduleService.getLastYearTotal(paramLaborLastYear)
      .subscribe((res: any) => {

        this.lastYearTotal = { dblTotalHours: 0, dblTotalPay: 0 };
        if (res.data != null)
          this.lastYearTotal = res.data;
        else
          this.lastYearTotal = { dblTotalHours: 0, dblTotalPay: 0 };
      })
    );
  }

  getWeatherData(param) {
    let paramWeather = Object.assign([], param);
    let paramObject = {
      dateTimeStart: !this.paramEmployeeSchedule.tScheduleByDay ? paramWeather.dateTimeFrom : this._datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      dateTimeEnd: !this.paramEmployeeSchedule.tScheduleByDay ? paramWeather.dateTimeTo : this._datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      strZipCode: this.selectedBusinessUnit.strZipCode,
      strIndustrySubType: this.strIndustrySubType
    };

    this._subscription.add(this._empScheduleService.getWeather(paramObject)
      .subscribe((res: any) => {
        if (!this.paramEmployeeSchedule.tScheduleByDay)
          this.setWeatherData(res);
        else
          this.setDayViewWeather(res);
      })
    );
  }

  getWeatherDayViewData() {
    let paramObject = {
      dateTimeStart: this._datePipe.transform(this.selectedDate, "yyyy-MM-dd"),
      strZipCode: this.selectedBusinessUnit.strZipCode,
      strIndustrySubType: this.strIndustrySubType
    };

    this._subscription.add(this._empScheduleService.getWeatherHourly(paramObject)
      .subscribe((res: any) => {
        this.setWeatherData(res);
      })
    );
  }

  setWeatherData(res) {
    if (!this.paramEmployeeSchedule.tScheduleByDay) {
      this.listSchedule.listScheduleDays.forEach(day => {
        day.tempMax = 0;
        day.tempMin = 0;
        day.icon = '';
        day.summary = '';
        day.tWeatherAvailable = false;

        if (res != undefined && res != null && res.detail != null && res.detail != undefined) {
          var weather = res.detail.filter(tt => this._datePipe.transform(day.dateTimeBusinessDate, "yyyyMMdd") === this._datePipe.transform(tt.dateTimeWeather, "yyyyMMdd"));
          if (weather != null && weather != undefined && weather.length > 0) {
            day.tempMax = weather[0].tempMax;
            day.tempMin = weather[0].tempMin;
            day.icon = weather[0].icon;
            day.summary = weather[0].summary;
            day.tWeatherAvailable = true;
          }
        }
      });
    }
    else {
      this.listSchedule.listLaborDayPart.forEach(daypart => {
        daypart.tempMax = 0;
        daypart.tempMin = 0;
        daypart.icon = '';
        daypart.summary = '';
        daypart.tWeatherAvailable = false;

        if (res != undefined && res != null && res.detail != null && res.detail != undefined) {
          var weather = res.detail.filter(tt => this._datePipe.transform(daypart.dateTimeEnd, "yyyyMMdd-H-tt") === this._datePipe.transform(tt.dateTimeWeather, "yyyyMMdd-H-tt"));
          if (weather != null && weather != undefined && weather.length > 0) {
            daypart.tempMax = weather[0].tempMax;
            daypart.tempMin = weather[0].tempMin;
            daypart.icon = weather[0].icon;
            daypart.summary = weather[0].summary;
            daypart.tWeatherAvailable = true;
          }
        }
      });
    }
  }

  setDayViewWeather(res) {
    this.dayViewWeather = { tempMax: 0, tempMin: 0, icon: '', summary: '', tWeatherAvailable: false };
    if (res != undefined && res != null && res.detail != null && res.detail != undefined) {
      var weather = res.detail.filter(tt => this._datePipe.transform(this.selectedDate, "yyyyMMdd") === this._datePipe.transform(tt.dateTimeWeather, "yyyyMMdd"));
      if (weather != null && weather != undefined && weather.length > 0) {
        this.dayViewWeather.tempMax = weather[0].tempMax;
        this.dayViewWeather.tempMin = weather[0].tempMin;
        this.dayViewWeather.icon = weather[0].icon;
        this.dayViewWeather.summary = weather[0].summary;
        this.dayViewWeather.tWeatherAvailable = true;
      }
    }
  }

  closeModal(id: string) {
    this._modalService.close(id);
    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
    this.tShow = true;
    this.employeeScheduleModel = new EmployeeScheduleModel();
  }

  saveSchedule(obj) {
    this.toastr.clear();
    let strEmailAddress = localStorage.getItem("userEmailAddress");
    this.paramEmployeeScheduleSave.strEmailAddress = strEmailAddress;
    this.paramEmployeeScheduleSave.tIsActual = this.paramEmployeeSchedule.tIsActual && !this.paramEmployeeSchedule.tScheduleByDay;
    this.paramEmployeeScheduleSave.tIsPublished = this.tIsPublished;
    this.paramEmployeeScheduleSave.tCanEditPublish = this.tCanEditPublishedSchedule;
    this._subscription.add(
      this._empScheduleService.saveEmployeeScheduleDetails(this.paramEmployeeScheduleSave).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            this.toastr.success("", res.strMessage, {
              timeOut: 3000
            });

            this.strSelectedTime = "";
            this.employeeScheduleModel = new EmployeeScheduleModel();
            this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
            this.closeModal("custom-modal-1");

            this.getEmployeScheduleDetails(false);
          }
          else {
            if (obj) {
              this.toastr.error(res.strMessage);
              this.employeeScheduleModel = new EmployeeScheduleModel();
            }
            this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: res.strMessage };
          }
        })
    );

  }

  openModalNotes(id: string, scheduleDay: any) {
    this.scheduleNotes.dateTimeDay = this._datePipe.transform(scheduleDay.dateTimeBusinessDate, this.strDateFormatDB) + " " + this._datePipe.transform(scheduleDay.dateTimeBusinessDate, this.strTimeFormatDB);
    this.scheduleNotes.iBusinessUnitId = this.iBusinessUnitId;
    this.scheduleNotes.iScheduleNotesId = scheduleDay.iScheduleNotesId;
    this.scheduleNotes.strNotes = scheduleDay.strNotes;
    this._modalService.open(id);
  }

  closeModalNotes(id: string) {
    this._modalService.close(id);
  }

  ChangeView() {
    this.listSchedule = {
      listScheduleDays: [], listScheduleByJob: [], listDayTime: [],
      dblScheduleHoursFinalPay: 0, dblScheduleHoursFinalTotal: 0, iTotalSchedules: 0, listLaborDayPart: []
    };
    this.paramEmployeeSchedule.tScheduleByDay = !this.paramEmployeeSchedule.tScheduleByDay;
    this.payPeriod = this._DateService.getPayrollDates(this.selectedDate);

    if (this.paramEmployeeSchedule.tScheduleByDay) {
      this.payPeriod = {
        payrollPeriodEndDate: this.payPeriod.payrollPeriodStartDate,
        payrollPeriodStartDate: this.payPeriod.payrollPeriodStartDate
      };
      this.selectedDate = this.payPeriod.payrollPeriodStartDate;
    }
    else
      this.selectedDate = this.payPeriod.payrollPeriodEndDate;


    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
    this.getEmployeScheduleDetails(false);

  }

  saveNotes() {
    this._subscription.add(
      this._scheduleNotesService.saveScheduleNotes(this.scheduleNotes).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            this.toastr.success("", res.strMessage, {
              timeOut: 3000
            });
            this.getEmployeScheduleDetails(false);
          }
          else {
            this.toastr.error(res.strMessage);
          }
          this.scheduleNotes = new ScheduleNotes();
          this.closeModal("mdl-notes");
        })
    );
  }

  HideDropdown() {
    $('.w-dropdown')
      .removeClass('active right left under above')

    $('*[data-drop]').removeClass('active');
  }

  CopyFrom(copyFromDate: CopyFromDates) {

    this.paramsCopySchedule = new ParamsCopySchedule();
    this.paramsCopySchedule.iBusinessUnitId = this.iBusinessUnitId;
    this.paramsCopySchedule.dateTimeCopyFromStart = copyFromDate.periodStartDate;
    this.paramsCopySchedule.dateTimeCopyFromEnd = copyFromDate.periodEndDate;
    this.paramsCopySchedule.dateTimeCopyToStart = this.payPeriod.payrollPeriodStartDate;
    this.paramsCopySchedule.listUserDepartmentAccess = this.listUserDepartmentIds;


    this._subscription.add(
      this._empScheduleService.getTerminatedEmployeeDetails(this.paramsCopySchedule).subscribe(
        (res: any) => {
          this.listTerminatedEmployees = res.data;

          if (this.listTerminatedEmployees.length > 0) {
            this.HideDropdown();
            this._modalService.open("mdl-terminationEmployees");
            return;
          }
          if (!confirm("Are you sure you want to copy schedule from " + copyFromDate.periodEndDate + "? It will delete all existing records from current week"))
            return;

          this.CopyScheduleData();
        })
    );

  }

  CopyScheduleData() {
    this.HideDropdown();
    this._modalService.close("mdl-terminationEmployees");
    this._subscription.add(
      this._empScheduleService.copyFromEmployeeSchedule(this.paramsCopySchedule).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            this.toastr.success("", res.strMessage, {
              timeOut: 3000
            });
            this.getEmployeScheduleDetails(false);
          }
          else {
            this.toastr.error(res.strMessage);
          }
          this.closeModal("custom-modal-1");
        })
    );
  }

  displayTimePicket() {
    this.tShow = true;
  }

  ComparisionFilter(iComparisionFilter) {
    this.strCurrentComparision = iComparisionFilter == 2 ? "13 wk avg" : "Last week";
    this.paramEmployeeSchedule.iComparisionFilter = iComparisionFilter;
    this.getEmployeScheduleDetails(false);
    $("#btnComparision").click();
  }

  ComparisionFilterLastYear(iFilter) {
    this.showLoader = true;
    this.strCurrentComparisionLastYear = iFilter == 2 ? "Budget" : "Last Year";
    this.iComparisionFilterLastYear = iFilter;
    this.getLastYearActual(this.paramEmployeeSchedule);
    $("#btnComparisionLastYear").click();
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);
  }

  getBusinessUnits() {
    this.listBusinessUnit = [];
    this._businessUnitService.getBusinessUnits().subscribe(
      (res: any) => {
        if (res.tIsSuccess) {
          this.listBusinessUnit = res.data.filter(x => x.iBusinessUnitId != this.iBusinessUnitId);
          var buSelected = this.listBusinessUnitEM.filter(x => x.iEMLocationId == this.iBusinessUnitId);
          if (buSelected != null && buSelected.length > 0)
            this.strBuName = buSelected[0].strBusinessUnitDisplayName;
        }
        else {
          this.toastr.error(res.strMessage);
        }
      });
  }

  FilterDepartment(iDepartmentId) {

    if (iDepartmentId > 0) {
      this.paramEmployeeSchedule.listUserDepartmentAccess = [iDepartmentId];

      this.tFilterDeaprtment = true;
    }
    else {
      this.paramEmployeeSchedule.listUserDepartmentAccess = this.listUserDepartmentIds;

      this.tFilterDeaprtment = false;
    }

    this.getEmployeScheduleDetails(false);
    $("#dropDownFilter").click();
  }

  getActualScheduleCount(scheduleByDate: any) {
    return scheduleByDate.listEmployeeSchedule.filter(x => x.iEmployeeScheduleId > 0).length;
  }

  ShowEmployeeTextBox() {
    this.tShowEmployeeFilter = this.tShowEmployeeFilter == false ? true : false;
    if (this.search != '') {
      this.search = '';
      this.FilterEmployee();
    }
  }

  onLocationChange() {
    this.tShowEmployeeFilter = false;
    this.paramEmployeeSchedule.strEmployeeSearch = '';
    this.getEmployeScheduleDetails(true);
  }

  FilterEmployee() {
    this.paramEmployeeSchedule.strEmployeeSearch = this.search;
    this.getEmployeScheduleDetails(false);
  }

  public shiftSave(tSuccess: boolean): void {
    if (tSuccess) {
      this.strSelectedTime = "";
      this.employeeScheduleModel = new EmployeeScheduleModel();
      this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
      this.addshiftBorrowedComponent.closeModalAddShift("mdl-addShift");
      this.getEmployeScheduleDetails(false);
    }
  }

  public shiftAddEditSuccess(tSuccess: boolean): void {
    if (tSuccess) {
      this.strSelectedTime = "";
      this.employeeScheduleModel = new EmployeeScheduleModel();
      this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
      this.adEditShiftComponent.closeModal("custom-modal-1");
      this.getEmployeScheduleDetails(false);
    }
  }

  public openAddShiftBorrowedPopup(): void {
    this.addshiftBorrowedComponent.listSchedule = this.listSchedule;
    this.addshiftBorrowedComponent.iBusinessUnitId = this.iBusinessUnitId;
    this.addshiftBorrowedComponent.payPeriod = this.payPeriod;
    this.addshiftBorrowedComponent.employeeScheduleModel = this.employeeScheduleModel;
    this.addshiftBorrowedComponent.listBusinessUnit = this.listBusinessUnit;
    this.addshiftBorrowedComponent.listUserDepartmentIds = this.listUserDepartmentIds;
    this.addshiftBorrowedComponent.shiftStart = this.shiftStart;
    this.addshiftBorrowedComponent.tShowBorowwed = this.listBusinessUnit != null && this.listBusinessUnit.length > 0;
    this.addshiftBorrowedComponent.openModalAddShift("mdl-addShift");
  }

  public openAddShift(id, schedule): void {
    this.paramEmployeeScheduleSave.tIsPublished = this.tIsPublished;
    this.paramEmployeeScheduleSave.tCanEditPublish = this.tCanEditPublishedSchedule;
    this.adEditShiftComponent.listSchedule = this.listSchedule;
    this.adEditShiftComponent.iBusinessUnitId = this.iBusinessUnitId;
    this.adEditShiftComponent.payPeriod = this.payPeriod;
    this.adEditShiftComponent.employeeScheduleModel = this.employeeScheduleModel;
    this.adEditShiftComponent.shiftStart = this.shiftStart;
    this.adEditShiftComponent.dblHRLimit = this.dblHRLimit;
    this.adEditShiftComponent.paramEmployeeScheduleSave = this.paramEmployeeScheduleSave;
    this.adEditShiftComponent.openModal(id, schedule);
  }

  public openEditShift(id, schedule): void {
    this.paramEmployeeScheduleSave.tIsPublished = this.tIsPublished;
    this.paramEmployeeScheduleSave.tCanEditPublish = this.tCanEditPublishedSchedule;
    this.adEditShiftComponent.listSchedule = this.listSchedule;
    this.adEditShiftComponent.iBusinessUnitId = this.iBusinessUnitId;
    this.adEditShiftComponent.payPeriod = this.payPeriod;
    this.adEditShiftComponent.employeeScheduleModel = this.employeeScheduleModel;
    this.adEditShiftComponent.shiftStart = this.shiftStart;
    this.adEditShiftComponent.dblHRLimit = this.dblHRLimit;
    this.adEditShiftComponent.paramEmployeeScheduleSave = this.paramEmployeeScheduleSave;
    this.adEditShiftComponent.editModal(id, schedule);
  }

  getLength(listEmployeeSchedule) {
    return listEmployeeSchedule.filter(x => x.iEmployeeScheduleId > 0).length;
  }

  ExporttoPDF(orientation) {
    if (this.strBuName != null && this.strBuName != "") {
      this._empScheduleService.exportToPDF(this.listSchedule, this.strBuName, this.strCustomerGroup, orientation).subscribe(
        (res: any) => {

          if (res.tValid)
            this.downloadFile(res.downloadUrl);
          else
            this.toastr.error("There are no schedules available to export.");
        });
    }
    else
      this.toastr.error("Something went wrong, please reload page & try again.");
  }

  downloadFile(fileUrl): void {
    this._empScheduleService.getFile(fileUrl)
      .subscribe(fileData => {
        let b: any = new Blob([fileData], { type: 'application/pdf' });

        var blobURL = window.URL.createObjectURL(b);
        var anchor = document.createElement("a");
        anchor.download = fileUrl + '.pdf';
        anchor.href = blobURL;
        anchor.click();
      }
      );
  }

  drop(ev, event) {
    if (this.trsanferSchedule != undefined && this.trsanferSchedule != null
      && ev.listEmployeeSchedule != undefined && ev.listEmployeeSchedule.length > 0) {
      let listAvailableSchedule = ev.listEmployeeSchedule.filter(tt => tt.iEmployeeScheduleId === this.trsanferSchedule.iEmployeeScheduleId);
      if (listAvailableSchedule.length <= 0) {
        this.dropAndSave(ev, event);
      }
    }
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev, event) {
    var img = document.createElement("img");
    if (event.shiftKey)
      img.src = "assets/images/copy.png";
    else
      img.src = "assets/images/move.png";

    event.dataTransfer.setDragImage(img, 0, 0);

    this.trsanferSchedule = new EmployeeScheduleModel();
    this.trsanferSchedule = Object.assign(this.trsanferSchedule, ev);
  }

  dropTimeSlot(ev, event, time, slot) {

    if (this.trsanferSchedule != undefined && this.trsanferSchedule != null
      && ev.listEmployeeSchedule != undefined && ev.listEmployeeSchedule.length > 0) {
      var actualTime = moment(time.toUpperCase(), ["h:mm A"]).format("HH:mm");
      var checkInOld = moment(this.trsanferSchedule.dateTimeCheckIn);
      var checkOutOld = moment(this.trsanferSchedule.dateTimeCheckOut);
      let exatMinutes = checkOutOld.diff(checkInOld, 'minutes');
      var date = checkInOld.format(this.strDateFormat);

      var checkIn = moment(date + ' ' + actualTime).add(slot, 'minutes');
      var checkOut = checkIn.clone();
      checkOut = checkOut.add(exatMinutes, 'minutes');

      this.trsanferSchedule.dateTimeCheckIn = this._datePipe.transform(checkIn.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkIn.toDate(), this.strTimeFormatDB);
      this.trsanferSchedule.dateTimeCheckOut = this._datePipe.transform(checkOut.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkOut.toDate(), this.strTimeFormatDB);

      this.dropAndSave(ev, event);
    }
  }

  dropAndSave(ev, event) {
    this.employeeScheduleModel = new EmployeeScheduleModel();
    this.employeeScheduleModel = Object.assign(this.employeeScheduleModel, ev.listEmployeeSchedule[0]);

    if (event.shiftKey)
      this.employeeScheduleModel.iEmployeeScheduleId = -1;
    else
      this.employeeScheduleModel.iEmployeeScheduleId = this.trsanferSchedule.iEmployeeScheduleId;

    let BuDate = moment(this.employeeScheduleModel.dateTimeBusinessDate);
    let checkIn = moment(this.trsanferSchedule.dateTimeCheckIn);
    let checkOut = moment(this.trsanferSchedule.dateTimeCheckOut);

    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];

    let dataFrom = this._datePipe.transform(BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkIn.toDate(), this.strTimeFormatDB);
    let dataTo = this._datePipe.transform(BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkOut.toDate(), this.strTimeFormatDB);

    this.employeeScheduleModel.dateTimeCheckIn = dataFrom.toString();
    this.employeeScheduleModel.dateTimeCheckOut = dataTo.toString();
    this.employeeScheduleModel.tIsDayTimeOff = this.trsanferSchedule.tIsDayTimeOff;
    this.employeeScheduleModel.tIsTimeOff = this.trsanferSchedule.tIsTimeOff;

    this.paramEmployeeScheduleSave.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
    this.paramEmployeeScheduleSave.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
    this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);

    this.saveSchedule(true);
  }

  getIntVal(Left) {
    return parseInt(Left);
  }

  onResizeEnd(event, schedule, id) {


    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
    this.employeeScheduleModel = Object.assign(this.employeeScheduleModel, schedule);

    var checkInOld = moment(this.employeeScheduleModel.dateTimeCheckIn);
    var checkOutOld = moment(this.employeeScheduleModel.dateTimeCheckOut);
    var checkOut = null;
    var checkIn = null;
    //
    if (event.edges.right != undefined) {
      let minutes = (event.edges.right / 64) * 60;
      checkOut = checkOutOld.add(minutes, 'minutes');
      var getMinutes = this._datePipe.transform(checkOut.toDate(), "mm");
      let fromMinute = this._DateService.nearest(getMinutes, 0, 5000, 1000);
      let dateCheckOut = moment(checkOut).toDate().setMinutes(fromMinute);
      checkOut = moment(dateCheckOut);
      //if(checkOutOld.toDate().toString() != checkOut.toDate().toString())
      //post=true;
    }
    else if (event.edges.left != undefined) {
      let minutes = (event.edges.left / 64) * 60;
      checkIn = checkInOld.add(minutes, 'minutes');
      var getMinutes = this._datePipe.transform(checkIn.toDate(), "mm");
      let fromMinute = this._DateService.nearest(getMinutes, 0, 5000, 1000);
      let dateCheckIn = moment(checkIn).toDate().setMinutes(fromMinute);
      checkIn = moment(dateCheckIn);
      // if(checkInOld.toDate().toString() != checkIn.toDate().toString())
      // post=true;
    }
    //if(post == true){

    if (checkIn != null)
      this.employeeScheduleModel.dateTimeCheckIn = this._datePipe.transform(checkIn.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkIn.toDate(), this.strTimeFormatDB);
    if (checkOut != null)
      this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(checkOut.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(checkOut.toDate(), this.strTimeFormatDB);

    this.paramEmployeeScheduleSave.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
    this.paramEmployeeScheduleSave.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
    this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);

    this.saveSchedule(true);
    //} 
  }

  getFormula() {
    this._subscription.add(this._setupService.getFormulaByPayrollUnit()
      .subscribe((res: any) => {
        this.formulaSetupModel = new FormulaSetupModel();
        if (res.tIsSuccess) {
          this.formulaSetupModel = res.data;
        }
        else {
          this.toastr.error(res.strMessage);
        }
      })
    );
  }

  updatePublishScheduleStatus(tIsPublished) {
    if (this.payPeriod != undefined) {
      let strEmailAddress = localStorage.getItem("userEmailAddress");
      let payload = {
        iBusinessUnitId: this.iBusinessUnitId,
        dateTimeStart: this.payPeriod.payrollPeriodStartDate,
        dateTimeEnd: this.payPeriod.payrollPeriodEndDate,
        strLastModifiedBy: strEmailAddress,
        tIsPublished
      };

      this._subscription.add(this._empScheduleService.updatePublishScheduleStatus(payload).subscribe((res: any) => {
        if (!res.tIsSuccess) {
          this.toastr.error(res.strMessage);
          return;
        }

        this.tIsPublished = tIsPublished;
        this.toastr.success("", res.strMessage, { timeOut: 3000 });
      }));
    }
  }

  get tIsCurrentOrFutureWeek() {
    if (!this.payPeriod)
      return false;

    let tIsCurrentWeek = moment().isBetween(moment(this.payPeriod.payrollPeriodStartDate), moment(this.payPeriod.payrollPeriodEndDate));
    let tIsFutureWeek = moment().isBefore(moment(this.payPeriod.payrollPeriodEndDate));

    return tIsCurrentWeek || tIsFutureWeek;
  }

  ChangeViewForActual() {
    this.paramEmployeeSchedule.tIsActual = !this.paramEmployeeSchedule.tIsActual;
    this.listSchedule = {
      listScheduleDays: [], listScheduleByJob: [], listDayTime: [],
      dblScheduleHoursFinalPay: 0, dblScheduleHoursFinalTotal: 0, iTotalSchedules: 0, listLaborDayPart: []
    };
    this.paramEmployeeSchedule.tIsActual = !this.paramEmployeeSchedule.tIsActual;
    this.payPeriod = this._DateService.getPayrollDates(this.selectedDate);

    this.selectedDate = this.payPeriod.payrollPeriodEndDate;

    this.getPreviousWeekDates();
    this.getPayrollStatus();
    this.getBusinessUnitConfig();
    this.getEmployeScheduleDetails(false);

  }

  getBreaks(listEmployeeScheduleByDate) {
    let obj = listEmployeeScheduleByDate.filter(x => x.iEmployeeScheduleId > 0 && x.iTimeEntryType != 1 && x.iTimeEntryType != 2 && x.iTimeEntryType != 3);
    if (obj != null && obj != undefined && obj.length > 0) return obj;
    else return [];
  }

  sortData(data) {
    return data.sort((a, b) => {
      return <any>new Date(a.dateTimeCheckIn) - <any>new Date(b.dateTimeCheckIn);
    });
  }

  tIsPublishAccess() {
    return (this.paramEmployeeSchedule.tIsActual || (this.tIsPublished && !this.tCanEditPublishedSchedule));
  }

  getName() {
    if (this.paramEmployeeSchedule.tIsActual && this._datePipe.transform(new Date(this.paramEmployeeSchedule.dateTimeFrom).getUTCDateExtention(), this.strDateFormatDB) == this._datePipe.transform(new Date().getUTCDateExtention(), this.strDateFormatDB))
      return 'Scheduled';
    else
      return this.paramEmployeeSchedule.tIsActual ? (this.iWeekStatus == 1 ? 'Projected' : this.iWeekStatus == 2 ? 'Actual' : 'Scheduled') : 'Scheduled';
  }

  tDisplayActualForOneShift(index, scheduleByDate, schedule) {
    return scheduleByDate.iActualCount == 1 && index == 0 && scheduleByDate.tIsActual
      && !schedule.tIsTimeOff && schedule.dateTimeCheckIn != schedule.dateTimeCheckOut;
  }

  tShowBorderDivShift(scheduleByDate, schedule, i) {
    return !scheduleByDate.tIsActual
      && this.getLength(scheduleByDate.listEmployeeSchedule) > 0
      && scheduleByDate.listEmployeeSchedule.length != this.getLength(scheduleByDate.listEmployeeSchedule)
      && i == 0 && !schedule.tIsTimeOff
      && scheduleByDate.listEmployeeSchedule.length > 2
      && schedule.dateTimeCheckIn != schedule.dateTimeCheckOut;
  }
  
  getTimeDetails() {
    this.listTimeDetails = [];
    for (let i = 0; i < 24; i++) {
      let timeDetails = new TimeDetails();
      let date = new Date(new Date().setHours(i, 0, 0, 0));
      let hours = date.getHours() == 0 ? "12" : date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
      let ampm = date.getHours() < 12 ? "AM" : "PM";
   //   let formattedTime = hours + ":" + minutes + " " + ampm;
     
      timeDetails.fromTime = date;
      timeDetails.toTime = new Date(new Date(date).setHours(date.getHours() + 1));
    let fromTime =  this._datePipe.transform( timeDetails.fromTime, "h:mm a");
    let toTime =   this._datePipe.transform( timeDetails.toTime, "h:mm a");
    let formattedTime = fromTime + " - " + toTime;
    timeDetails.strTimeName = formattedTime;
      timeDetails.iTimeSlot = i;
      this.listTimeDetails.push(timeDetails);
    }
  }

  exportExcel(): void {
    // setTimeout(() => { 
    if (this.Finaldata.length <= 0) {
      this.toastr.error("", "There are no schedules available to export.", {
        timeOut: 2000
      });
      return;
    }
    let time = "";
    this.fileName = "";

    let hour = new Date().getHours().toString();
    let min = new Date().getMinutes().toString();
    let sec = new Date().getSeconds().toString();
    time = hour.concat(min, sec);
    this.fileName = 'No_of_employees_by_hour_from_' + this._datePipe.transform(this.listSchedule.listScheduleDays[0].dateTimeBusinessDate, 'MMdd') + '_to_' + this._datePipe.transform(this.listSchedule.listScheduleDays[6].dateTimeBusinessDate, 'MMdd') + '_' + time + '.xlsx';

    let excelHTMLData = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(excelHTMLData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
    this.showExcelLoader = false;
    // }, 5000);

  }

 setExportExcelData(){
  this.showExcelLoader = true;
  this.getTimeDetails();
  this.Finaldata=[];
  this.listSchedule.listScheduleByJob.forEach(job => {
    job.listEmployee.forEach(emp => {
      emp.listEmployeeScheduleByDate.forEach(scheduleByDate => {
        scheduleByDate.listEmployeeSchedule.forEach(empScedule => {
          if (empScedule.iEmployeeScheduleId > 0 && !empScedule.tIsTimeOff) {
            this.Finaldata.push(empScedule);
          }
        });
      });
    });
  });
  let dateslist = this.listSchedule.listScheduleDays;

  this.listTimeDetails.forEach(element => {
    element.diffInHours = 0;
    this.listExportToExcelSchedule = [];
    dateslist.forEach(date => {
      date.diffInHours = 0;

      let dOutDate = null;
      let excelData = new ExportEmployeeSchedule();
      excelData.date = date.dateTimeBusinessDate;
      excelData.hours = 0;
      excelData.day = date.strDay;
      this.listExportToExcelSchedule.push(excelData);

      let eleInTime = this._datePipe.transform(element.fromTime, "h:mm a");
      let eleOutTime = this._datePipe.transform(element.toTime, "h:mm a");
      if (element.iTimeSlot == 23) {
        let addDate = new Date(date.dateTimeBusinessDate);
        let getAddDate = addDate.setDate(addDate.getDate() + 1);
        dOutDate = this._datePipe.transform(getAddDate, "yyyy-MM-dd");
      }

      let dInDate = this._datePipe.transform(date.dateTimeBusinessDate, "yyyy-MM-dd");
      let fromDateConcate = dInDate + " " + eleInTime;
      let toDateConcate = "";
      if (element.iTimeSlot == 23) {
        toDateConcate = dOutDate + " " + eleOutTime;
      } else {
        toDateConcate = dInDate + " " + eleOutTime;
      }
      let timeSlotFromDate = new Date(fromDateConcate);
      let timeSlotToDate = new Date(toDateConcate);
      let filterdata = this.Finaldata.filter(f => f.dateTimeBusinessDate == date.dateTimeBusinessDate);

      if (filterdata.length > 0) {
        filterdata.forEach(d => {
          let timeIn = new Date(d.dateTimeCheckIn);
          let timeOut = new Date(d.dateTimeCheckOut);
          let calcuatedCheckInTime = null;
          let calcuatedCheckOutTime = null;

          if ((timeIn >= timeSlotFromDate && timeIn < timeSlotToDate) || (timeOut >= timeSlotFromDate && timeOut < timeSlotToDate) ||
            (timeSlotFromDate >= timeIn && timeSlotFromDate < timeOut) || (timeSlotToDate >= timeIn && timeSlotToDate < timeOut) ||
            (timeIn >= timeSlotFromDate && timeOut < timeSlotToDate) || (timeSlotFromDate >= timeIn && timeSlotToDate < timeOut)) {

            if (timeIn > timeSlotFromDate) {
              calcuatedCheckInTime = timeIn;
            } else {
              calcuatedCheckInTime = timeSlotFromDate;
            }

            if (timeOut > timeSlotToDate) {
              calcuatedCheckOutTime = timeSlotToDate;
            } else {
              calcuatedCheckOutTime = timeOut;
            }

            let diffInMs = Date.parse(calcuatedCheckOutTime) - Date.parse(calcuatedCheckInTime);
            let diffInHours = diffInMs / 1000 / 60 / 60;
            element.diffInHours += diffInHours;
            excelData.hours += diffInHours;
          }
        });
      }
      element.dateslist = this.listExportToExcelSchedule;
    });
  });
  this.getSumOfHour();
  this.listExportScheduleTotalHours = this.listTimeDetails[0].dateslist;
  this.getTotalSumOfHours();

 }
  // Sum of hours for particular day
  getSumOfHour() {
    let totMon = 0; let totTue = 0; let totWed = 0; let totThru = 0; let totFri = 0; let totSat = 0; let totSun = 0;
    this.listTimeDetails.forEach(element => {
      element.dateslist.forEach(e => {
        if (e.day == "Mon") {
          totMon += e.hours;
        }
        if (e.day == "Tue") {
          totTue += e.hours;
        }
        if (e.day == "Wed") {
          totWed += e.hours;
        }
        if (e.day == "Thu") {
          totThru += e.hours;
        }
        if (e.day == "Fri") {
          totFri += e.hours;
        }
        if (e.day == "Sat") {
          totSat += e.hours;
        }
        if (e.day == "Sun") {
          totSun += e.hours;
        }
      });
    });

    this.listTimeDetails.forEach(element => {
      element.dateslist.forEach(e => {
        if (e.day == "Mon") {
          e.totHrs = totMon;
        }
        if (e.day == "Tue") {
          e.totHrs = totTue;
        }
        if (e.day == "Wed") {
          e.totHrs = totWed;
        }
        if (e.day == "Thu") {
          e.totHrs = totThru;
        }
        if (e.day == "Fri") {
          e.totHrs = totFri;
        }
        if (e.day == "Sat") {
          e.totHrs = totSat;
        }
        if (e.day == "Sun") {
          e.totHrs = totSun;
        }
      });
    });
  }

  // Sum of all hours for all the days
  getTotalSumOfHours() {
    this.totoalHourSum = 0;
    this.listExportScheduleTotalHours.forEach(element => {
      this.totoalHourSum += element.totHrs;
    });
  }
}

