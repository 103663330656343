// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//  export const configUrl = "http://emweb.sunbeltsol.com/api/";
//  export const schedulingUrl = "http://em.sunbeltsol.com/";
export const configUrl = "http://emweb.sunbeltsol.com/api/";
//export const configUrl = "http://localhost:53489/api/";
export const schedulingUrl = "/";
// export const configUrl = "https://insightpay.analytix.com/apicore/api/";
// export const schedulingUrl = "/";
// export const salesApiUrl = "https://api.analytix.com/InsightSales/api/";

 export const salesApiUrl = "http://insightsales.sunbeltsol.com/api/";
export const weatherApiUrl="http://sites.sunbeltsol.com/scheduler/api/";
export const forcastApiUrl="http://dunkin.staginsight360.sunbeltsol.com/services/DunkinDonutService.asmx/";
