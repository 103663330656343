import { Component, OnInit } from '@angular/core';
//import { Select2OptionData } from 'ng2-select2';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-dashboard-copy',
  templateUrl: './dashboard-copy.component.html',
  styleUrls: ['./dashboard-copy.component.scss']
})
export class DashboardCopyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
    $( "#fixTable-footer" ).tableHeadFixer( { 'foot': true, "head": false, "left": 0, 'max-height': 400 } );
    $( "#fixTable" ).tableHeadFixer( { 'foot': false, "head": false, "left": 1 } );
    $( "#fixTable2" ).tableHeadFixer( { 'foot': false, "head": false, "left": 1 } );

    if($('.datepicker').length){
     
      $('.datepicker:not(.week)').datepicker({
        changeMonth: true,
        changeYear: true,
        afterShow: function(){
          $('.ui-datepicker-title select').select2({width: 'style',minimumResultsForSearch: -1,theme: 'uikit'});
        },
        minDate: new Date(),
        dateFormat: "mm/dd/yy",
        defaultDate: 1,
        monthNamesShort: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      }).datepicker('setDate', new Date());
  
      var customPeriod = -( $('.datepicker.custom-period').data('period') );
      $('.datepicker.custom-period').datepicker('option', 'minDate', customPeriod);
  
      $('.datepicker.week').datepicker({
        changeMonth: true,
        changeYear: true,
        beforeShow: function(el, inst) {
          // Add dates to value of input break datepicker current day selecting, so we need to update value before show
          var d = $(this).val().split(',')[0];
          $(this).datepicker('setDate', d);
        },
        afterShow: function(el, inst){
          $('.ui-datepicker-title select').select2({width: 'style',minimumResultsForSearch: -1,theme: 'uikit'});
          $('.ui-datepicker-current-day').parent().addClass('datepicker-current-week');
          $('.ui-datepicker-calendar').addClass('week');
        },
        minDate: new Date(),
        dateFormat: "mm/dd/yy",
        monthNamesShort: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        onSelect: function(dateText, inst) {
              var startD = $(this).datepicker('getDate');
              startD.setDate(startD.getDate() - (startD.getDay()));
              var endD = new Date(startD.getTime());
              endD.setDate(endD.getDate() + 6);
             
              $(this).siblings('span.form-item').html($.datepicker.formatDate('M d', startD) + ' - ' + $.datepicker.formatDate('M d', endD));
  
              var selectedDates = [];
              for (var i=0;i<7;i++) {
                var d = new Date( startD.getTime() );
                d.setDate(d.getDate() + i);
                selectedDates.push($.datepicker.formatDate('mm/dd/yy', d));
              }
              //selectedDates = selectedDates.join(', ');
              $(this).val(selectedDates);
          }
      }).datepicker('setDate', new Date());

     
    }
  }
}
