import { Component, OnInit } from '@angular/core';
import {FormulaSetupService} from '../service/formula-setup/formula-setup.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { FormulaSetupModel } from '../Models/FormulaSetupModel';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-formula-setup',
  templateUrl: './formula-setup.component.html',
  styleUrls: ['./formula-setup.component.scss']
})
export class FormulaSetupComponent implements OnInit {
  _subscription = new Subscription();
  listEnumBudget:Array<any>;
  listEnumGuest:Array<any>;
  listEnumSales:Array<any>;
  formulaSetupModel = new FormulaSetupModel();
  listEnumSalesReportFrom : Array<any>;

  constructor(private _setupService : FormulaSetupService, private toastr: ToastrService) { }

  ngOnInit() {
    this.loadSetupMasters();
    this.getFormula();
  }

  loadSetupMasters(){
    this._subscription.add(this._setupService.getFormulaMasters()
     .subscribe((res: any) => { 
      if(res.tIsSuccess){    
      this.listEnumBudget=res.data.listEnumBudget;
      this.listEnumGuest=res.data.listEnumGuest;
       this.listEnumSales = res.data.listEnumSales;
       this.listEnumSalesReportFrom = res.data.listEnumSalesReportFrom;
      }
      else{
        this.listEnumBudget=[];
        this.listEnumGuest=[];
        this.listEnumSales=[];
        this.toastr.error(res.strMessage);
      }
     })
    );
  }

  SaveFormula(){    
    this._subscription.add(this._setupService.saveFormula(this.formulaSetupModel)
     .subscribe((res: any) => {
       if(res.tIsSuccess){
        this.toastr.success("", res.strMessage, {
          timeOut: 3000
        });
        this.loadSetupMasters();
      }
      else{
        this.toastr.error(res.strMessage);
      }     
     })
    );
  }

  getFormula(){
    this._subscription.add(this._setupService.getFormulaByPayrollUnit()
     .subscribe((res: any) => {
      this.formulaSetupModel = new FormulaSetupModel();
      if(res.tIsSuccess){        
       this.formulaSetupModel=res.data;
      }
      else{
        this.toastr.error(res.strMessage);
      }
     })
    );
  }

  Cancel(){
    this.formulaSetupModel = new FormulaSetupModel();
  }
}
