import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { EmployeeScheduleService } from '../service/employee-schedule.service'
import { ScheduleNotesService } from '../service/schedulenotes.service'
import { ParamEmployeeSchedule, ParamEmployeeScheduleSave } from '../Models/ParamEmployeeSchedule';
import { DateService } from '../service/date.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { ModalService } from '../service/modal.service';
import { EmployeeScheduleModel } from '../Models/EmployeeScheduleModel';
import * as moment from 'moment';
import { DatePipe, DecimalPipe, Time } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../service/loader.service';

@Component({
  selector: 'app-add-edit-shift',
  templateUrl: './add-edit-shift.component.html',
  styleUrls: ['./add-edit-shift.component.scss']
})

export class AddEditShiftComponent implements OnInit {

  @ViewChild("inputTest") vc: ElementRef;

  @Input() shiftStart: any;
  @Input() listSchedule: any;
  @Input() iBusinessUnitId: number;
  @Input() dblHRLimit: number;
  @Output() saveScheduleAddEditSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  timeSlotArray = [0, 15, 30, 45, 60];
  strDateFormat: string = "MM/DD/YYYY";
  strDateFormatDB: string = "yyyy-MM-dd";
  strTimeFormatDB: string = "HH:mm:ss";
  strTimeDisplay: string = "hh:mm a";
  bodyText: string;
  paramEmployeeSchedule = new ParamEmployeeSchedule();
  paramEmployeeScheduleSave = new ParamEmployeeScheduleSave();

  selectedTimeFrom: any;
  selectedTimeTo: any;
  selectedDate: Date;
  payPeriod: any;
  _subscription = new Subscription();
  subscription: Subscription;
  employeeScheduleModel = new EmployeeScheduleModel();
  employeeScheduleModelCopy = new EmployeeScheduleModel();
  tShow: boolean = true;
  tShowNotes: boolean = false;
  strSelectedTime: string;

  // Property to set configuration
  datePickerConfig = {
    format: "MM/DD/YYYY",
    firstDayOfWeek: "su",
    unSelectOnClick: false,
    disableKeypress: true
  };
  datepickerTheme = "angular-datepicker";
  timepickerTheme = "angular-timepicker";
  tIsSubmitted: boolean = false;

  tShowHRLimitWarning: boolean = false;

  shiftTotalHours: number = 0;
  popupErrorBox: any = { tShowErrorBox: false, strErrorMessage: '' };
  selectedHourFrom: string;
  selectedHourTo: string;
  selectedMinuteFrom: string;
  selectedMinuteTo: string;

  constructor(
    private _empScheduleService: EmployeeScheduleService,
    private _modalService: ModalService, private _datePipe: DatePipe,
    private toastr: ToastrService,
    private loaderService: LoaderService) {
    this.payPeriod = {
      payrollPeriodStartDate: new Date(),
      payrollPeriodEndDate: new Date()
    };
  }

  setFocus() {
    this.tShow = false;
    this.vc.nativeElement.focus();
    this.vc.nativeElement.select();
  }
  setOpenEditshiftTime() {

    let hrfrom = moment(this.selectedTimeFrom).format("hh");
    this.selectedHourFrom = hrfrom;
    let hrto = moment(this.selectedTimeTo).format("hh");
    this.selectedHourTo = hrto;

    let mifrom = moment(this.selectedTimeFrom).format("mm");
    this.selectedMinuteFrom = mifrom;
    let mito = moment(this.selectedTimeTo).format("mm");
    this.selectedMinuteTo = mito;

  }

  setDefaultTime() {

    if (this.selectedHourFrom == "" || this.selectedHourFrom == undefined || isNaN(parseInt(this.selectedHourFrom)))
      this.selectedHourFrom = "12";
    else if (this.selectedHourFrom.length <= 1)
      this.selectedHourFrom = "0" + this.selectedHourFrom;
    else if (parseInt(this.selectedHourFrom) > 12)
      this.selectedHourFrom = "12";

    if (this.selectedHourTo == "" || this.selectedHourTo == undefined || isNaN(parseInt(this.selectedHourTo)))
      this.selectedHourTo = "12";
    else if (this.selectedHourTo.length <= 1)
      this.selectedHourTo = "0" + this.selectedHourTo;
    else if (parseInt(this.selectedHourTo) > 12)
      this.selectedHourTo = "12";

    if (this.selectedMinuteFrom == "" || this.selectedMinuteFrom == undefined || isNaN(parseInt(this.selectedMinuteFrom)))
      this.selectedMinuteFrom = "00";
    else if (this.selectedMinuteFrom.length <= 1)
      this.selectedMinuteFrom = "0" + this.selectedMinuteFrom;
    else if (parseInt(this.selectedMinuteFrom) > 59)
      this.selectedMinuteFrom = "00";

    if (this.selectedMinuteTo == "" || this.selectedMinuteTo == undefined || isNaN(parseInt(this.selectedMinuteTo)))
      this.selectedMinuteTo = "00";
    else if (this.selectedMinuteTo.length <= 1)
      this.selectedMinuteTo = "0" + this.selectedMinuteTo;
    else if (parseInt(this.selectedMinuteTo) > 59)
      this.selectedMinuteTo = "00";
  }

  fromHourUP() {
    this.setDefaultTime();

    let fromHr = (parseInt(this.selectedHourFrom) + 1) < 10 ? "0" + (parseInt(this.selectedHourFrom) + 1).toString() : (parseInt(this.selectedHourFrom) + 1).toString();
    if (parseInt(fromHr) > 12)
      this.selectedHourFrom = "01";
    else
      this.selectedHourFrom = fromHr;

    this.setTimeToPicker();
  }
  fromHourDown() {
    this.setDefaultTime();
    let fromHr = (parseInt(this.selectedHourFrom) - 1) < 10 ? (parseInt(this.selectedHourFrom) - 1) < 0 ? "12" : "0" + (parseInt(this.selectedHourFrom) - 1).toString() : (parseInt(this.selectedHourFrom) - 1).toString();
    if (parseInt(fromHr) > 12)
      this.selectedHourFrom = "01";
    else
      this.selectedHourFrom = fromHr;

    this.setTimeToPicker();
  }
  toHourUP() {
    this.setDefaultTime();
    let toHr = (parseInt(this.selectedHourTo) + 1) < 10 ? "0" + (parseInt(this.selectedHourTo) + 1).toString() : (parseInt(this.selectedHourTo) + 1).toString();
    if (parseInt(toHr) >= 12)
      this.selectedHourTo = "01";
    else
      this.selectedHourTo = toHr;

    this.setTimeToPicker();
  }
  toHourDown() {
    this.setDefaultTime();
    let toHr = (parseInt(this.selectedHourTo) - 1) < 10 ? (parseInt(this.selectedHourTo) - 1) < 0 ? "12" : "0" + (parseInt(this.selectedHourTo) - 1).toString() : (parseInt(this.selectedHourTo) - 1).toString();
    if (parseInt(toHr) > 12)
      this.selectedHourTo = "01";
    else
      this.selectedHourTo = toHr;

    this.setTimeToPicker();
  }
  MinuteUP(type) {
    this.setDefaultTime();

    let number = type == 'from' ? parseInt(this.selectedMinuteFrom) : parseInt(this.selectedMinuteTo);

    let index = this.timeSlotArray.findIndex(x => { return x == number; });
    if (index == this.timeSlotArray.length - 1)
      number = 0;
    else {
      let Available = this.timeSlotArray.filter(x => {
        return x == number;
      });

      if (Available.length > 0)
        number = number + 1;
    }

    let fromMinute = this.closest(number, this.timeSlotArray, true);

    if (type == 'from') {
      if (parseInt(fromMinute) >= 60)
        this.selectedMinuteFrom = "00";
      else
        this.selectedMinuteFrom = fromMinute;
    }
    else {
      if (parseInt(fromMinute) >= 60)
        this.selectedMinuteTo = "00";
      else
        this.selectedMinuteTo = fromMinute;
    }

    this.setTimeToPicker();
  }
  MinuteDown(type) {
    this.setDefaultTime();
    let number = type == 'from' ? parseInt(this.selectedMinuteFrom) : parseInt(this.selectedMinuteTo);

    let index = this.timeSlotArray.findIndex(x => { return x == number; });
    if (index == 0) {
      if (type == 'from')
        this.selectedMinuteFrom = "45";
      else
        this.selectedMinuteTo = "45";
    }
    else {
      let Available = this.timeSlotArray.filter(x => {
        return x == number;
      });

      if (Available.length > 0)
        number = number - 1;
      let fromMinute = this.closest(number, this.timeSlotArray, false);
      if (type == 'from') {
        if (parseInt(fromMinute) >= 60)
          this.selectedMinuteFrom = "00";
        else
          this.selectedMinuteFrom = fromMinute;
      }
      else {
        if (parseInt(fromMinute) >= 60)
          this.selectedMinuteTo = "00";
        else
          this.selectedMinuteTo = fromMinute;
      }
    }
    this.setTimeToPicker();
  }

  fromChange() {
    this.setDefaultTime();
    this.setTimeToPicker();
  }

  closest(number, arr, tIncrease) {
    let next;
    if (tIncrease)
      next = Math.max.apply(Math, arr);
    else
      next = Math.min.apply(Math, arr);

    for (var val = 0; val < arr.length; val++) {
      if (tIncrease) {
        if (arr[val] >= number && arr[val] < next)
          next = arr[val];
      }
      else {
        if (arr[val] <= number && arr[val] > next)
          next = arr[val];
      }
    }

    let curr = next;
    curr = (curr >= 10) ? curr : '0' + curr; //If result is 5, to display '05' instead of '5'
    return curr;
  }

  setTimeToPicker() {
    var dateFrom = moment(this.selectedTimeFrom).format(this.strDateFormat);
    var AmPmFrom = moment(this.selectedTimeFrom).format("A");
    var AmPmTo = moment(this.selectedTimeTo).format("A");
    var timeFrom = this.selectedHourFrom + ":" + this.selectedMinuteFrom + " " + AmPmFrom;
    var timeTo = this.selectedHourTo + ":" + this.selectedMinuteTo + " " + AmPmTo;
    this.selectedTimeFrom = moment(dateFrom + " " + timeFrom);
    this.selectedTimeTo = moment(dateFrom + " " + timeTo);
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  openModal(id: string, schedule: any) {

    this.shiftTotalHours = 0;
    this.tShowHRLimitWarning = false;

    this._modalService.open(id);
    this.employeeScheduleModel = new EmployeeScheduleModel();

    this.employeeScheduleModel = Object.assign(this.employeeScheduleModel, schedule);

    this.selectedTimeFrom = moment(this.shiftStart);
    this.selectedTimeTo = moment(this.shiftStart).add(30, 'minutes');

    this.employeeScheduleModel.dblTotalHours = 0;
    this.employeeScheduleModel.iEmployeeScheduleId = -1;
    this.employeeScheduleModel.strNotes = '';
    this.employeeScheduleModel.iBusinessUnitId = this.iBusinessUnitId;
    this.setShiftTime();
    this.strSelectedTime = this._datePipe.transform(this.selectedTimeFrom, this.strTimeDisplay) + " - " + this._datePipe.transform(this.selectedTimeTo, this.strTimeDisplay);
    this.tShowNotes = false;
    this.popupErrorBox = { tShowErrorBox: false, strErrorMessage: '' };
    this.setOpenEditshiftTime();

  }

  closeModal(id: string) {
    this._modalService.close(id);
    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
    this.tShow = true;
    this.employeeScheduleModel = new EmployeeScheduleModel();
  }

  saveEmployeeSchedule() {
    if (this.validate()) {
      if (this.employeeScheduleModel.tIsDayTimeOff || this.employeeScheduleModel.dateTimeCheckIn != this.employeeScheduleModel.dateTimeCheckOut) {
        this.paramEmployeeScheduleSave.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
        this.paramEmployeeScheduleSave.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
        if (this.employeeScheduleModel.tIsDayTimeOff && this.employeeScheduleModel.tIsTimeOff) {
          if (this.paramEmployeeScheduleSave.listEmployeeScheduleModel.length <= 0)
            this.setShiftTime();
          this.employeeScheduleModel.dateTimeCheckIn = this.employeeScheduleModel.dateTimeBusinessDate;
          this.employeeScheduleModel.dateTimeCheckOut = this.employeeScheduleModel.dateTimeBusinessDate;
        }
        else if (!this.employeeScheduleModel.tIsTimeOff) {
          this.employeeScheduleModel.tIsDayTimeOff = false;
        }

        this.saveSchedule(false);
      }
      else {
        this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Selected shift hours should be more than 0 hours.' };
      }
    }
  }

  saveSchedule(obj) {
    this.toastr.clear();
    this.loaderService.show();
    let strEmailAddress = localStorage.getItem("userEmailAddress");
    this.paramEmployeeScheduleSave.strEmailAddress = strEmailAddress;
    this.paramEmployeeScheduleSave.tIsActual = this.paramEmployeeSchedule.tIsActual && !this.paramEmployeeSchedule.tScheduleByDay;
   
    this._subscription.add(
      this._empScheduleService.saveEmployeeScheduleDetails(this.paramEmployeeScheduleSave).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            this.toastr.success("", res.strMessage, {
              timeOut: 3000
            });

            this.strSelectedTime = "";
            this.employeeScheduleModel = new EmployeeScheduleModel();
            this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
            this.closeModal("custom-modal-1");
            //this.getEmployeScheduleDetails();
            this.saveScheduleSuccess(true);
            setTimeout(() => {
              this.loaderService.hide();
            }, 1000);
          }
          else {
            if (obj) {
              this.toastr.error(res.strMessage);
              this.employeeScheduleModel = new EmployeeScheduleModel();
            }
            this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: res.strMessage };
            this.loaderService.hide();
          }
        })
    );

  }

  validate() {
    var tIsValid = true;
    if (this.employeeScheduleModel.iEmployeeId == 0
      || this.employeeScheduleModel.iEmployeeId == null
      || this.employeeScheduleModel.iEmployeeId == undefined) {
      //this.toastr.error("Please select employee");
      this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Please select employee' };
      tIsValid = false;
    }
    else if (this.employeeScheduleModel.iEmploymentDetailId == 0
      || this.employeeScheduleModel.iEmploymentDetailId == null
      || this.employeeScheduleModel.iEmploymentDetailId == undefined) {
      //this.toastr.error("Please select role");
      this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Please select role' };
      tIsValid = false;
    }
    else {
      var employee = this.getEmployeeDetails();
      if (employee != null && employee.length > 0 && !(
        moment(employee[0].dateTimeEffective).toDate() <= moment(this.employeeScheduleModel.dateTimeBusinessDate).toDate() &&
        moment(employee[0].dateTimeExpiry).toDate() >= moment(this.employeeScheduleModel.dateTimeBusinessDate).toDate()
      )
      ) {
        this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'No active Job found for selected date.' };
        tIsValid = false;
      }
      return tIsValid;
    }
  }

  setShiftTime() {
    let BuDate = moment(this.employeeScheduleModel.dateTimeBusinessDate);
    let checkIn = moment(this.employeeScheduleModel.dateTimeCheckIn);
    let checkOut = moment(this.employeeScheduleModel.dateTimeCheckOut);
    let selShiftFrom = moment(this.selectedTimeFrom);
    let selShiftTo = moment(this.selectedTimeTo);

    this.employeeScheduleModel.dateTimeBusinessDateDisplay = this._datePipe.transform(BuDate.toDate(), "MM/d/y");

    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
    this.tShow = true;
    var dataFrom = moment(this._datePipe.transform(BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(selShiftFrom.toDate(), this.strTimeFormatDB));
    var dataTo = moment(this._datePipe.transform(BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(selShiftTo.toDate(), this.strTimeFormatDB));

    var dataCheckIn = moment(this._datePipe.transform(this.employeeScheduleModel.tIsDayTimeOff ? checkIn.toDate() : BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(dataFrom, this.strTimeFormatDB)).toDate();
    var dataCheckOut = moment(this._datePipe.transform(this.employeeScheduleModel.tIsDayTimeOff ? checkOut.toDate() : BuDate.toDate(), this.strDateFormatDB) + " " + this._datePipe.transform(dataTo, this.strTimeFormatDB)).toDate();

    if (dataCheckIn.getDate() >= dataCheckOut.getDate() && dataCheckIn > dataCheckOut) {
      dataCheckOut.setDate(dataCheckIn.getDate() + 1);
    }

    this.employeeScheduleModel.dateTimeBusinessDate = this._datePipe.transform(BuDate.toDate(), this.strDateFormatDB + " " + this.strTimeFormatDB);

    this.employeeScheduleModel.dateTimeCheckIn = this._datePipe.transform(dataCheckIn, this.strDateFormatDB) + " " + this._datePipe.transform(dataFrom, this.strTimeFormatDB);
    this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(dataCheckOut, this.strDateFormatDB) + " " + this._datePipe.transform(dataTo, this.strTimeFormatDB);
    this.employeeScheduleModel.dblTotalHours = moment(this.employeeScheduleModel.dateTimeCheckOut).diff(moment(this.employeeScheduleModel.dateTimeCheckIn), 'minutes') / 60;

    this.shiftTotalHours = moment(this.employeeScheduleModel.dateTimeCheckOut).diff(moment(this.employeeScheduleModel.dateTimeCheckIn), 'minutes') / 60;
    this.strSelectedTime = this._datePipe.transform(dataFrom, this.strTimeDisplay) + " - " + this._datePipe.transform(dataTo, this.strTimeDisplay);

    this.getEmployeeAllSchedule();

    this.tShowHRLimitWarning = this.employeeScheduleModel.dblTotalHours > this.dblHRLimit ? true : false;

    if (this.employeeScheduleModel.iEmployeeScheduleId <= 0)
      this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);

  }

  getEmployeeAllSchedule() {
    if (this.listSchedule.listScheduleByJob != null) {
      this.listSchedule.listScheduleByJob.forEach(alljobs => {
        var Job = this.listSchedule.listScheduleByJob.filter(jobs => jobs.iJobTitleId == alljobs.iJobTitleId);
        if (Job.length > 0) {
          var employee = Job[0].listEmployee.filter(employe => employe.iEmployeeId == this.employeeScheduleModel.iEmployeeId);
          if (employee.length > 0) {
            var employeeScheduleByDate = employee[0].listEmployeeScheduleByDate.filter(schedule =>
              moment(schedule.dateTimeBusinessDate).format(this.strDateFormatDB) ==
              moment(this.employeeScheduleModel.dateTimeBusinessDate).format(this.strDateFormatDB));
            if (employeeScheduleByDate.length > 0) {
              employeeScheduleByDate[0].listEmployeeSchedule.forEach(schedule => {
                if (alljobs.iJobTitleId == this.employeeScheduleModel.iJobTitleId && schedule.iEmployeeScheduleId > 0 && this.employeeScheduleModel.iEmployeeScheduleId != schedule.iEmployeeScheduleId)
                  this.employeeScheduleModel.dblTotalHours = this.employeeScheduleModel.dblTotalHours + moment(schedule.dateTimeCheckOut).diff(moment(schedule.dateTimeCheckIn), 'minutes') / 60;

                if (schedule.iEmployeeScheduleId > 0) {
                  if (this.employeeScheduleModel.iEmployeeScheduleId == schedule.iEmployeeScheduleId)
                    this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);
                }
              });
            }
          }
        }
      });
    }
  }

  getEmployeeDetails() {
    let employeeDetail = null;
    if (this.listSchedule.listScheduleByJob != null) {
      this.listSchedule.listScheduleByJob.forEach(alljobs => {
        var Job = this.listSchedule.listScheduleByJob.filter(jobs => jobs.iJobTitleId == alljobs.iJobTitleId);
        if (Job.length > 0) {
          var employee = Job[0].listEmployee.filter(employe => employe.iEmployeeId == this.employeeScheduleModel.iEmployeeId);
          if (employee.length > 0) {
            employeeDetail = employee;
          }
        }
      });
    }
    return employeeDetail;
  }

  editModal(id: string, schedule: any) {

    this.tShowHRLimitWarning = false;
    this._modalService.open(id);
    this.employeeScheduleModel = new EmployeeScheduleModel();
    this.employeeScheduleModel.dblTotalHours = 0;
    //this.tShowBusinessUnit = true;
    //this.employeeScheduleModel = schedule;
    this.employeeScheduleModel = Object.assign(this.employeeScheduleModel, schedule);
    //this.employeeScheduleModelCopy=schedule;
    var dataFrom = moment(schedule.dateTimeCheckIn).toDate();
    var dataTo = moment(schedule.dateTimeCheckOut).toDate();
    this.selectedTimeFrom = moment(schedule.dateTimeCheckIn);
    this.selectedTimeTo = moment(schedule.dateTimeCheckOut);

    this.strSelectedTime = this._datePipe.transform(dataFrom, this.strTimeDisplay) + " - " + this._datePipe.transform(dataTo, this.strTimeDisplay);
    this.setShiftTime();
    this.popupErrorBox = { tShowErrorBox: false, strErrorMessage: '' };
    if (this.employeeScheduleModel.strNotes != "")
      this.tShowNotes = true;
    else
      this.tShowNotes = false;

    this.setOpenEditshiftTime();
  }

  deleteSchedule() {
    if (confirm("Are you sure, you want to delete shift ?")) {
      if (this.employeeScheduleModel.iEmployeeScheduleId <= 0)
        this.toastr.error("Please select schedule for delete");
      else {
        let strEmailAddress = localStorage.getItem("userEmailAddress");
        let payload = {
          iEmployeeScheduleId: this.employeeScheduleModel.iEmployeeScheduleId,
          iBusinessUnitId: this.employeeScheduleModel.iBusinessUnitId,
          dateTimeStart: this.payPeriod.payrollPeriodStartDate,
          dateTimeEnd: this.payPeriod.payrollPeriodEndDate,
          strEmailAddress
        };
        this._subscription.add(
          this._empScheduleService.deleteEmployeeScheduleDetails(payload).subscribe(
            (res: any) => {
              if (res.tIsSuccess) {
                this.toastr.success("", res.strMessage, {
                  timeOut: 3000
                });
                this.strSelectedTime = "";
                this.employeeScheduleModel = new EmployeeScheduleModel();
                this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
                this.closeModal("custom-modal-1");
                this.saveScheduleSuccess(true);
              }
              else {
                this.toastr.error(res.strMessage);
              }
            })
        );
      }
    }
  }

  displayTimePicket() {
    this.tShow = true;
  }

  changeTimeOff() {
    this.displayTimePicket();
    if (this.employeeScheduleModel.tIsDayTimeOff) {
      this.setBusinessUnitTime();
    }
  }

  setBusinessUnitTime() {

    this.selectedTimeFrom = moment(this.shiftStart);
    this.selectedTimeTo = moment(this.shiftStart).add(30, 'minutes');
    this.employeeScheduleModel.dateTimeCheckIn = this._datePipe.transform(this.employeeScheduleModel.dateTimeBusinessDate, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeFrom, this.strTimeFormatDB);
    this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(this.employeeScheduleModel.dateTimeBusinessDate, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeTo, this.strTimeFormatDB);
    this.shiftTotalHours = 0.5;
    this.employeeScheduleModel.dblTotalHours = 0.5;
    this.strSelectedTime = this._datePipe.transform(this.employeeScheduleModel.dateTimeCheckIn, this.strTimeDisplay) + " - " + this._datePipe.transform(this.employeeScheduleModel.dateTimeCheckOut, this.strTimeDisplay);
    this.setOpenEditshiftTime();
  }

  saveScheduleSuccess(tSuccess: boolean) {
    this.saveScheduleAddEditSuccess.next(tSuccess);
  }

  changeWholeDay() {
    //this.displayTimePicket();    
    this.employeeScheduleModel.tIsDayTimeOff = !this.employeeScheduleModel.tIsDayTimeOff;
    this.tShowNotes = this.employeeScheduleModel.tIsDayTimeOff ? true : false;
    if (!this.employeeScheduleModel.tIsDayTimeOff)
      this.setBusinessUnitTime();
  }
}
