import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as env from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormulaSetupService {

  constructor(private http: HttpClient) { }

  getFormulaMasters() {
    return this.http.get(env.configUrl + "FormulaSetup");
  }
  getFormulaByPayrollUnit() {
    return this.http.get(env.configUrl + "FormulaSetup/getsetupformula/");
  }

  saveFormula(setupFormula : any) {
    return this.http.post(env.configUrl + "FormulaSetup",setupFormula);
  }
}
