import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BusinessUnitService } from '../service/business-unit/business-unit.service'
import { BusinessUnitModel } from '../Models/BusinessUnitModel';
import { EmployeeModel } from '../Models/EmployeeModel';
import { EmployeeScheduleModel } from '../Models/EmployeeScheduleModel';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../service/employee/employee.service'
import { DatePipe, DecimalPipe, Time } from '@angular/common';
import { ModalService } from '../service/modal.service';
declare var $: any;
import * as moment from 'moment';
import { ParamEmployeeScheduleSave } from '../Models/ParamEmployeeSchedule';
import { EmployeeScheduleService } from '../service/employee-schedule.service'
import { CurrentuserService } from '../service/currentuser.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addshift-borrowed',
  templateUrl: './addshift-borrowed.component.html',
  styleUrls: ['./addshift-borrowed.component.scss']
})
export class AddshiftBorrowedComponent implements OnInit {
  @ViewChild("inputTestAddShift") vcAdd: ElementRef;
  @Input() iBusinessUnitId: number;
  @Input() listSchedule: any;
  @Input() employeeScheduleModel = new EmployeeScheduleModel();
  @Input() payPeriod: any;
  @Input() listUserDepartmentIds: any;
  @Input() shiftStart: any;
  @Input() listBusinessUnit: Array<BusinessUnitModel>;
  @Input() tShowBorowwed: boolean;
  @Output() savechanges: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() tIsActual: boolean;
  @Input() tIsPublished: boolean;
  @Input() tCanEditPublish: boolean;

  timeSlotArray = [0, 15, 30, 45, 60];
  listEmployeeAddShift: Array<EmployeeModel>;
  strDateFormat: string = "MM/DD/YYYY";
  strDateFormatDB: string = "yyyy-MM-dd";
  strTimeFormatDB: string = "HH:mm:ss";
  strTimeDisplay: string = "hh:mm a";
  iBusinessUnitAddShift: number;
  tShowBusinessUnit: boolean = true;
  selectedEmployee: any;
  selectedEmployment: any;
  selectedTimeFromAddShift: any;
  selectedTimeToAddShift: any;
  //listBusinessUnit: Array<BusinessUnitModel>;
  _subscription = new Subscription();
  listEmployeeJobs: Array<any>;
  selectedDateAddShift: any;
  tShowAddShift: boolean = true;
  strSelectedTime: string;
  dblHRLimit: number = 0;
  tShowHRLimitWarning: boolean = false;
  paramEmployeeScheduleSave = new ParamEmployeeScheduleSave();
  popupErrorBox: any = { tShowErrorBox: false, strErrorMessage: '' };
  tShowNotesAddShift: boolean;
  datepickerTheme = "angular-datepicker";
  timepickerTheme = "angular-timepicker";
  datePickerConfigAddShift = {
    format: "MM/DD/YYYY",
    firstDayOfWeek: "su",
    unSelectOnClick: false,
    disableKeypress: true
  };
  selectedHourFrom: string;
  selectedHourTo: string;
  selectedMinuteFrom: string;
  selectedMinuteTo: string;

  constructor(
    private _currentUserService: CurrentuserService,
    private _businessUnitService: BusinessUnitService,
    private _datePipe: DatePipe,
    private toastr: ToastrService,
    private _employeeService: EmployeeService,
    private _modalService: ModalService,
    private _empScheduleService: EmployeeScheduleService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this._currentUserService.getUserSession().subscribe((res: any) => {
      if (res.token == null) {
        this.router.navigate(['/session']);
        return;
      }
    });
  }
  setFocusAddShift() {
    this.tShowAddShift = false;
    this.vcAdd.nativeElement.focus();
    this.vcAdd.nativeElement.select();
  }
  callParent(tSuccess: boolean) {
    this.savechanges.next(tSuccess);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  getEmployeeJobs() {
    this.popupErrorBox = { tShowErrorBox: false, strErrorMessage: '' };
    this.listEmployeeJobs = [];
    this.employeeScheduleModel.iEmploymentDetailId = 0;
    if (this.employeeScheduleModel.iEmployeeId != undefined && this.employeeScheduleModel.iEmployeeId != null) {
      this._employeeService.getEmployeeJobs(this.employeeScheduleModel.iEmployeeId).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            var listJobs: any = [];
            res.data.forEach(x => {
              if (this.listUserDepartmentIds.filter(e => e === x.iDepartmentId).length > 0) {
                listJobs.push(x);
              }
            });
            this.listEmployeeJobs = listJobs;
            //this.listEmployeeJobs = res.data;
            if (this.listEmployeeJobs.length == 1)
              this.employeeScheduleModel.iEmploymentDetailId = this.listEmployeeJobs[0].iEmploymentDetailId;
          }
          else {
            this.toastr.error(res.strMessage);
          }
        });
    }
  }

  saveEmployeeScheduleAddShift() {
    this.employeeScheduleModel.dateTimeBusinessDate = this._datePipe.transform(this.selectedDateAddShift, this.strDateFormatDB + " " + this.strTimeFormatDB);
    if (this.employeeScheduleModel.tIsDayTimeOff) {
      this.employeeScheduleModel.dateTimeCheckIn = this.employeeScheduleModel.dateTimeBusinessDate;
      var dat = new Date(this.employeeScheduleModel.dateTimeBusinessDate);
      var check = new Date(this.employeeScheduleModel.dateTimeBusinessDate);
      check.setDate(dat.getDate() + 1);
      this.selectedTimeFromAddShift = dat;
      this.selectedTimeToAddShift = check;
      this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(check, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedDateAddShift, this.strTimeFormatDB);
    }
    this.saveEmployeeSchedule();
  }

  openModalAddShift(id: string) {
    this._modalService.open(id);
    this.listEmployeeAddShift = [];
    this.listEmployeeJobs = [];
    this.tShowBusinessUnit = true;
    this.tShowNotesAddShift = true;
    this.getListEmployee();
    this.setDefaultAddShift();
    this.popupErrorBox = { tShowErrorBox: false, strErrorMessage: '' };
    this.setOpenEditshiftTime();
  }

  closeModalAddShift(id: string) {
    this._modalService.close(id);
    this.tShowAddShift = !this.tShowAddShift;
    this.tShowBusinessUnit = false;
    this.employeeScheduleModel = new EmployeeScheduleModel();
    this.addBorrowedEmpoyee();
  }

  setDefaultAddShift() {
    this.employeeScheduleModel = new EmployeeScheduleModel();
    this.employeeScheduleModel.iEmployeeScheduleId = 0;
    this.iBusinessUnitAddShift = 0;

    this.selectedDateAddShift = moment(this.payPeriod.payrollPeriodStartDate).format(this.strDateFormat.toString());

    this.selectedTimeFromAddShift = moment(this.shiftStart);
    this.selectedTimeToAddShift = moment(this.shiftStart).add(30, 'minutes');

    this.strSelectedTime = this._datePipe.transform(this.selectedTimeFromAddShift, this.strTimeDisplay) + " - " + this._datePipe.transform(this.selectedTimeToAddShift, this.strTimeDisplay);
    this.employeeScheduleModel.iBusinessUnitId = this.iBusinessUnitId;
    this.employeeScheduleModel.dateTimeBusinessDate = this._datePipe.transform(this.selectedDateAddShift, this.strDateFormatDB + " " + this.strTimeFormatDB);
    this.setShiftTimeAddshift();
  }

  setShiftTimeAddshift() {

    this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];

    var dataCheckIn = moment(this._datePipe.transform(this.selectedDateAddShift, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeFromAddShift, this.strTimeFormatDB));
    var dataCheckOut = moment(this._datePipe.transform(this.selectedDateAddShift, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeToAddShift, this.strTimeFormatDB));

    if (dataCheckIn.toDate().getDate() >= dataCheckOut.toDate().getDate() && dataCheckIn > dataCheckOut) {
      dataCheckOut = moment(this._datePipe.transform(moment(dataCheckIn).add(24, 'hours'), this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeToAddShift, this.strTimeFormatDB));
    }
    this.employeeScheduleModel.dateTimeCheckIn = this._datePipe.transform(dataCheckIn, this.strDateFormatDB) + " " + this._datePipe.transform(dataCheckIn, this.strTimeFormatDB);
    this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(dataCheckOut, this.strDateFormatDB) + " " + this._datePipe.transform(dataCheckOut, this.strTimeFormatDB);
    this.employeeScheduleModel.dblTotalHours = moment(this.employeeScheduleModel.dateTimeCheckOut).diff(moment(this.employeeScheduleModel.dateTimeCheckIn), 'minutes') / 60;

    this.strSelectedTime = this._datePipe.transform(dataCheckIn, this.strTimeDisplay) + " - " + this._datePipe.transform(dataCheckOut, this.strTimeDisplay);

    this.getEmployeeAllSchedule();
    this.tShowHRLimitWarning = this.employeeScheduleModel.dblTotalHours > this.dblHRLimit ? true : false;
    if (this.employeeScheduleModel.iEmployeeScheduleId <= 0)
      this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);

    this.tShowAddShift = true;
  }

  getEmployeeAllSchedule() {

    if (this.listSchedule.listScheduleByJob != null) {
      this.listSchedule.listScheduleByJob.forEach(alljobs => {
        var Job = this.listSchedule.listScheduleByJob.filter(jobs => jobs.iJobTitleId == alljobs.iJobTitleId);
        if (Job.length > 0) {
          var employee = Job[0].listEmployee.filter(employe => employe.iEmployeeId == this.employeeScheduleModel.iEmployeeId);
          if (employee.length > 0) {
            var employeeScheduleByDate = employee[0].listEmployeeScheduleByDate.filter(schedule =>
              moment(schedule.dateTimeBusinessDate).format(this.strDateFormatDB) ==
              moment(this.employeeScheduleModel.dateTimeBusinessDate).format(this.strDateFormatDB));
            if (employeeScheduleByDate.length > 0) {
              employeeScheduleByDate[0].listEmployeeSchedule.forEach(schedule => {
                if (alljobs.iJobTitleId == this.employeeScheduleModel.iJobTitleId && schedule.iEmployeeScheduleId > 0 && this.employeeScheduleModel.iEmployeeScheduleId != schedule.iEmployeeScheduleId)
                  this.employeeScheduleModel.dblTotalHours = this.employeeScheduleModel.dblTotalHours + moment(schedule.dateTimeCheckOut).diff(moment(schedule.dateTimeCheckIn), 'minutes') / 60;

                if (schedule.iEmployeeScheduleId > 0) {
                  if (this.employeeScheduleModel.iEmployeeScheduleId == schedule.iEmployeeScheduleId)
                    this.paramEmployeeScheduleSave.listEmployeeScheduleModel.push(this.employeeScheduleModel);
                }
              });
            }
          }
        }
      });
    }
  }

  addBorrowedEmpoyee() {
    this.tShowBusinessUnit = !this.tShowBusinessUnit;
    this.tShowAddShift = true;
    this.listEmployeeAddShift = [];
    this.listEmployeeJobs = [];
    this.popupErrorBox = { tShowErrorBox: false, strErrorMessage: '' };
    this.setDefaultAddShift();
    if (this.tShowBusinessUnit) {
      this.getListEmployee();
    }
  }

  getListEmployee() {
    this.listEmployeeAddShift = new Array<EmployeeModel>();
    if (this.listSchedule.listScheduleByJob != null) {
      this.listSchedule.listScheduleByJob.forEach(employee => {
        employee.listEmployee.forEach(emp => {
          var existEmployee = this.listEmployeeAddShift.find(x => x.iEmployeeId == emp.iEmployeeId);
          if (existEmployee == undefined || existEmployee == null) {
            var addEmployee = new EmployeeModel();
            addEmployee.iEmployeeId = emp.iEmployeeId;
            addEmployee.iBusinessUnitId = emp.iHomeBusinessUnitId;
            addEmployee.strLastName = emp.strLastName;
            addEmployee.strFirstName = emp.strFirstName;
            addEmployee.strFullName = emp.strLastName + ", " + emp.strFirstName;
            this.listEmployeeAddShift.push(addEmployee);
          }
        });
      });
    }
  }

  saveEmployeeSchedule() {
    if (this.validate()) {
      if (this.employeeScheduleModel.tIsDayTimeOff || this.employeeScheduleModel.dateTimeCheckIn != this.employeeScheduleModel.dateTimeCheckOut) {
        this.paramEmployeeScheduleSave.dateTimeFrom = this.payPeriod.payrollPeriodStartDate;
        this.paramEmployeeScheduleSave.dateTimeTo = this.payPeriod.payrollPeriodEndDate;
        if (this.employeeScheduleModel.tIsDayTimeOff && this.employeeScheduleModel.tIsTimeOff) {
          this.employeeScheduleModel.dateTimeCheckIn = this.employeeScheduleModel.dateTimeBusinessDate;
          this.employeeScheduleModel.dateTimeCheckOut = this.employeeScheduleModel.dateTimeBusinessDate;
        }
        else if (!this.employeeScheduleModel.tIsTimeOff) {
          this.employeeScheduleModel.tIsDayTimeOff = false;
        }
        let strEmailAddress = localStorage.getItem("userEmailAddress");
        this.paramEmployeeScheduleSave.strEmailAddress = strEmailAddress;
        this.paramEmployeeScheduleSave.tIsActual = this.tIsActual;
        this.paramEmployeeScheduleSave.tIsPublished = this.tIsPublished;
        this.paramEmployeeScheduleSave.tCanEditPublish = this.tCanEditPublish;
        this._subscription.add(
          this._empScheduleService.saveEmployeeScheduleDetails(this.paramEmployeeScheduleSave).subscribe(
            (res: any) => {
              if (res.tIsSuccess) {
                this.toastr.success("", res.strMessage, {
                  timeOut: 3000
                });

                this.strSelectedTime = "";
                this.employeeScheduleModel = new EmployeeScheduleModel();
                this.paramEmployeeScheduleSave.listEmployeeScheduleModel = [];
                this.closeModalAddShift("mdl-addShift");
              }
              else {
                this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: res.strMessage };
              }
              this.callParent(res.tIsSuccess);
            })
        );
      }
      else {
        this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Selected shift hours should be more that 0 hours.' };
      }
    }
  }

  validate() {
    var tIsValid = true;
    if ((this.iBusinessUnitAddShift == 0
      || this.iBusinessUnitAddShift == null
      || this.iBusinessUnitAddShift == undefined
    ) && !this.tShowBusinessUnit) {
      //this.toastr.error("Please select location");
      this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Please select location' };
      tIsValid = false;
    }
    else if (this.employeeScheduleModel.iEmployeeId == 0
      || this.employeeScheduleModel.iEmployeeId == null
      || this.employeeScheduleModel.iEmployeeId == undefined) {
      //this.toastr.error("Please select employee");
      this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Please select employee' };
      tIsValid = false;
    }
    else if (this.employeeScheduleModel.iEmploymentDetailId == 0
      || this.employeeScheduleModel.iEmploymentDetailId == null
      || this.employeeScheduleModel.iEmploymentDetailId == undefined) {
      //this.toastr.error("Please select role");
      this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'Please select role' };
      tIsValid = false;
    }
    else {
      var employee = this.getEmployeeDetails();
      if (employee != null && employee.length > 0 && !(
        moment(employee[0].dateTimeEffective).toDate() <= moment(this.employeeScheduleModel.dateTimeBusinessDate).toDate() &&
        moment(employee[0].dateTimeExpiry).toDate() >= moment(this.employeeScheduleModel.dateTimeBusinessDate).toDate()
      )
      ) {
        this.popupErrorBox = { tShowErrorBox: true, strErrorMessage: 'No active Job found for selected date.' };
        tIsValid = false;
      }
      return tIsValid;
    }
  }

  getEmployeeDetails() {
    let employeeDetail = null;
    if (this.listSchedule.listScheduleByJob != null) {
      this.listSchedule.listScheduleByJob.forEach(alljobs => {
        var Job = this.listSchedule.listScheduleByJob.filter(jobs => jobs.iJobTitleId == alljobs.iJobTitleId);
        if (Job.length > 0) {
          var employee = Job[0].listEmployee.filter(employe => employe.iEmployeeId == this.employeeScheduleModel.iEmployeeId);
          if (employee.length > 0) {
            employeeDetail = employee;
          }
        }
      });
    }
    return employeeDetail;
  }

  getEmployeeByBusinessUnit() {
    if (this.iBusinessUnitAddShift != null && this.iBusinessUnitAddShift > 0) {
      this.listEmployeeAddShift = new Array<EmployeeModel>();
      this.employeeScheduleModel.iEmployeeId = 0;
      this.employeeScheduleModel.iEmploymentDetailId = 0;
      this._employeeService.getEmployeeByBusinessUnit(this.iBusinessUnitAddShift).subscribe(
        (res: any) => {
          if (res.tIsSuccess) {
            this.listEmployeeAddShift = res.data;
          }
          else {
            this.toastr.error(res.strMessage);
          }
        });
    }
  }

  changeWholeDay() {
    this.employeeScheduleModel.tIsDayTimeOff = !this.employeeScheduleModel.tIsDayTimeOff;
    if (!this.employeeScheduleModel.tIsDayTimeOff)
      this.setBusinessUnitTime();
  }

  displayTimePicket() {
    this.tShowAddShift = true;
  }

  changeTimeOff() {
    //this.displayTimePicket();
    if (this.employeeScheduleModel.tIsDayTimeOff) {
      this.setBusinessUnitTime();
    }
  }

  setBusinessUnitTime() {

    this.selectedTimeFromAddShift = moment(this.shiftStart);
    this.selectedTimeToAddShift = moment(this.shiftStart).add(30, 'minutes');
    this.employeeScheduleModel.dateTimeCheckIn = this._datePipe.transform(this.employeeScheduleModel.dateTimeBusinessDate, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeFromAddShift, this.strTimeFormatDB);
    this.employeeScheduleModel.dateTimeCheckOut = this._datePipe.transform(this.employeeScheduleModel.dateTimeBusinessDate, this.strDateFormatDB) + " " + this._datePipe.transform(this.selectedTimeToAddShift, this.strTimeFormatDB);
    //this.shiftTotalHours = 0.5;
    this.employeeScheduleModel.dblTotalHours = 0.5;
    this.strSelectedTime = this._datePipe.transform(this.employeeScheduleModel.dateTimeCheckIn, this.strTimeDisplay) + " - " + this._datePipe.transform(this.employeeScheduleModel.dateTimeCheckOut, this.strTimeDisplay);
    this.setOpenEditshiftTime();
  }

  setOpenEditshiftTime() {
    let hrfrom = moment(this.selectedTimeFromAddShift).format("hh");
    this.selectedHourFrom = hrfrom;
    let hrto = moment(this.selectedTimeToAddShift).format("hh");
    this.selectedHourTo = hrto;

    let mifrom = moment(this.selectedTimeFromAddShift).format("mm");
    this.selectedMinuteFrom = mifrom;
    let mito = moment(this.selectedTimeToAddShift).format("mm");
    this.selectedMinuteTo = mito;
  }
  setDefaultTime() {

    if (this.selectedHourFrom == "" || this.selectedHourFrom == undefined || isNaN(parseInt(this.selectedHourFrom)))
      this.selectedHourFrom = "12";
    else if (this.selectedHourFrom.length <= 1)
      this.selectedHourFrom = "0" + this.selectedHourFrom;
    else if (parseInt(this.selectedHourFrom) > 12)
      this.selectedHourFrom = "12";

    if (this.selectedHourTo == "" || this.selectedHourTo == undefined || isNaN(parseInt(this.selectedHourTo)))
      this.selectedHourTo = "12";
    else if (this.selectedHourTo.length <= 1)
      this.selectedHourTo = "0" + this.selectedHourTo;
    else if (parseInt(this.selectedHourTo) > 12)
      this.selectedHourTo = "12";

    if (this.selectedMinuteFrom == "" || this.selectedMinuteFrom == undefined || isNaN(parseInt(this.selectedMinuteFrom)))
      this.selectedMinuteFrom = "00";
    else if (this.selectedMinuteFrom.length <= 1)
      this.selectedMinuteFrom = "0" + this.selectedMinuteFrom;
    else if (parseInt(this.selectedMinuteFrom) > 59)
      this.selectedMinuteFrom = "00";

    if (this.selectedMinuteTo == "" || this.selectedMinuteTo == undefined || isNaN(parseInt(this.selectedMinuteTo)))
      this.selectedMinuteTo = "00";
    else if (this.selectedMinuteTo.length <= 1)
      this.selectedMinuteTo = "0" + this.selectedMinuteTo;
    else if (parseInt(this.selectedMinuteTo) > 59)
      this.selectedMinuteTo = "00";
  }

  fromHourUP() {
    this.setDefaultTime();

    let fromHr = (parseInt(this.selectedHourFrom) + 1) < 10 ? "0" + (parseInt(this.selectedHourFrom) + 1).toString() : (parseInt(this.selectedHourFrom) + 1).toString();
    if (parseInt(fromHr) > 12)
      this.selectedHourFrom = "01";
    else
      this.selectedHourFrom = fromHr;

    this.setTimeToPicker();
  }
  fromHourDown() {
    this.setDefaultTime();
    let fromHr = (parseInt(this.selectedHourFrom) - 1) < 10 ? (parseInt(this.selectedHourFrom) - 1) < 0 ? "12" : "0" + (parseInt(this.selectedHourFrom) - 1).toString() : (parseInt(this.selectedHourFrom) - 1).toString();
    if (parseInt(fromHr) > 12)
      this.selectedHourFrom = "01";
    else
      this.selectedHourFrom = fromHr;

    this.setTimeToPicker();
  }
  toHourUP() {
    this.setDefaultTime();
    let toHr = (parseInt(this.selectedHourTo) + 1) < 10 ? "0" + (parseInt(this.selectedHourTo) + 1).toString() : (parseInt(this.selectedHourTo) + 1).toString();
    if (parseInt(toHr) >= 12)
      this.selectedHourTo = "01";
    else
      this.selectedHourTo = toHr;

    this.setTimeToPicker();
  }
  toHourDown() {
    this.setDefaultTime();
    let toHr = (parseInt(this.selectedHourTo) - 1) < 10 ? (parseInt(this.selectedHourTo) - 1) < 0 ? "12" : "0" + (parseInt(this.selectedHourTo) - 1).toString() : (parseInt(this.selectedHourTo) - 1).toString();
    if (parseInt(toHr) > 12)
      this.selectedHourTo = "01";
    else
      this.selectedHourTo = toHr;

    this.setTimeToPicker();
  }
  MinuteUP(type) {
    this.setDefaultTime();

    let number = type == 'from' ? parseInt(this.selectedMinuteFrom) : parseInt(this.selectedMinuteTo);

    let index = this.timeSlotArray.findIndex(x => { return x == number; });
    if (index == this.timeSlotArray.length - 1)
      number = 0;
    else {
      let Available = this.timeSlotArray.filter(x => {
        return x == number;
      });

      if (Available.length > 0)
        number = number + 1;
    }

    let fromMinute = this.closest(number, this.timeSlotArray, true);

    if (type == 'from') {
      if (parseInt(fromMinute) >= 60)
        this.selectedMinuteFrom = "00";
      else
        this.selectedMinuteFrom = fromMinute;
    }
    else {
      if (parseInt(fromMinute) >= 60)
        this.selectedMinuteTo = "00";
      else
        this.selectedMinuteTo = fromMinute;
    }

    this.setTimeToPicker();
  }
  MinuteDown(type) {
    this.setDefaultTime();
    let number = type == 'from' ? parseInt(this.selectedMinuteFrom) : parseInt(this.selectedMinuteTo);

    let index = this.timeSlotArray.findIndex(x => { return x == number; });
    if (index == 0) {
      if (type == 'from')
        this.selectedMinuteFrom = "45";
      else
        this.selectedMinuteTo = "45";
    }
    else {
      let Available = this.timeSlotArray.filter(x => {
        return x == number;
      });

      if (Available.length > 0)
        number = number - 1;
      let fromMinute = this.closest(number, this.timeSlotArray, false);
      if (type == 'from') {
        if (parseInt(fromMinute) >= 60)
          this.selectedMinuteFrom = "00";
        else
          this.selectedMinuteFrom = fromMinute;
      }
      else {
        if (parseInt(fromMinute) >= 60)
          this.selectedMinuteTo = "00";
        else
          this.selectedMinuteTo = fromMinute;
      }
    }
    this.setTimeToPicker();
  }
  closest(number, arr, tIncrease) {
    let next;
    if (tIncrease)
      next = Math.max.apply(Math, arr);
    else
      next = Math.min.apply(Math, arr);

    for (var val = 0; val < arr.length; val++) {
      if (tIncrease) {
        if (arr[val] >= number && arr[val] < next)
          next = arr[val];
      }
      else {
        if (arr[val] <= number && arr[val] > next)
          next = arr[val];
      }
    }

    let curr = next;
    curr = (curr >= 10) ? curr : '0' + curr; //If result is 5, to display '05' instead of '5'
    return curr;
  }
  fromChange() {
    this.setDefaultTime();
    this.setTimeToPicker();
  }

  setTimeToPicker() {
    var dateFrom = moment(this.selectedTimeFromAddShift).format(this.strDateFormat);
    var AmPmFrom = moment(this.selectedTimeFromAddShift).format("A");
    var AmPmTo = moment(this.selectedTimeToAddShift).format("A");
    var timeFrom = this.selectedHourFrom + ":" + this.selectedMinuteFrom + " " + AmPmFrom;
    var timeTo = this.selectedHourTo + ":" + this.selectedMinuteTo + " " + AmPmTo;
    this.selectedTimeFromAddShift = moment(dateFrom + " " + timeFrom);
    this.selectedTimeToAddShift = moment(dateFrom + " " + timeTo);
  }
}
